import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';

//For sessionLayoutComponent:
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../dashboard/Content/chart.js';
import Deposits from '../dashboard/Content/deposit.js';
import Orders from '../dashboard/Content/Order.js';
import TSession from './todaySession.js';
import FSessions from './FutureSessions.js'
import { useNavigate } from 'react-router-dom';
import { getEvents, addEvents } from '../calendar/calendarSlice.js';
import { createTodaySession, createSession, setSessions, setAllSessions, setStateWeeklyFocusPoint } from './sessionSlice.js'
import { filterEvents } from '../calendar/calendarSlice.js';
import PrintSessions from './printSessions.js';
import { sessionRequests, teamRequests } from '../../services/api-requests.js';
import Loader from '../../layouts/loader.js';

import { setAvailableTeams } from '../team/newTeam/teamSlice.js';
import WeeklyFocusPoint from './weeklyFocusPoint.js';
import EditIcon from '@mui/icons-material/Edit';

function Session() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [time, setTime] = useState('');
  const [stateSessionSplit, setStateSessionSplit] = useState(1);
  const [sessionType, setsessionType] = useState('');
  const [activeSessions, setActiveSessions] = useState('');
  const [teamId, setTeamId] = useState('');
  // const [createSession, setCreateSession] = useState(false);
  const [splitValues, setSplitValues] = useState([]);
  const [backendSplitValues, setBackendSplitValues] = useState([]);
  const [retrievedWeeklyFocusPoint, setRetrievedWeeklyFocusPoint] = React.useState('');
  const [iWantThis, setIWantThis] = useState([]);
  const [teamSessions, setTeamSessions] = useState([]);
  const [allSessions, setstateAllSessions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [isWeeklyFocusPopupOpen, setIsWeeklyFocusPopupOpen] = useState(false);
  const [isTeamSessionModal, setIsTeamSessionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [useAllTeams, setUseAllTeams] = useState(false);
  const events = useSelector((state) => state.calendar.events)
  const todayEvents = useSelector((state) => state.calendar.todayEvents)
  const futureEvents = useSelector((state) => state.calendar.futureEvents)

  const todaySessions = useSelector((state) => state.session.todaysSession);
  const futureSessions = useSelector((state) => state.session.sessions);

  const [allSessionsLoading, setAllSessionsLoading] = useState(false);
  const [teamSessionsLoading, setTeamSessionsLoading] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);

  const stateWeeklyFocusPoint = useSelector((state) => state.session.allSessions.weeklyFocusPoint)
  const allTeamWeeklyFocusPoint = useSelector((state) => state.session.weeklyFocusPoint)
  const schoolId = parseInt(useSelector((state) => state.user.profile.school))
  const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))
  const stateTeams = useSelector((state) => state.team.availableTeams)
  const activeTabTeam = useSelector((state) => state.session.activeSelectedTeam)
  const stateSessions = useSelector((state) => state.session.allSessions)




  useEffect(() => {
    // Update the splitValues array when stateSessionSplit changes

    setSplitValues(new Array(stateSessionSplit).fill(''));

  }, [stateSessionSplit]);



  useEffect(() => {
    //Here we need to find the weeklyFocusPoint for the selected Team
    if (activeTabTeam === "All Teams") {
      setRetrievedWeeklyFocusPoint(allTeamWeeklyFocusPoint ? allTeamWeeklyFocusPoint : '')
    } else {
      const teamIWant = focusPointArray.find((element) => element.name === activeTabTeam)

      setRetrievedWeeklyFocusPoint(teamIWant.weeklyFocusPoint.slice(0, -1))
    }

  }, [activeTabTeam])

  // useEffect(() => {
  //   fetchTeamSessions();
  //   fetchAllSessions();
  //   fetchTeams();
  // }, [])


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      await fetchTeams().then(() => {
        fetchTeamSessions();
        fetchAllSessions();
      })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [schoolId]);

  /////DO A MATCH BETWEEN ALL SESSIONS AND THE ACTIVETABTEAM

  useEffect(() => {
    // console.log('I WANT THIS: ', iWantThis)
  }, [iWantThis])



  useEffect(() => {
    dispatch(setAllSessions(allSessions));
  }, [allSessions])

  useEffect(() => {
    if (activeTabTeam === "All Teams") {
      setTeamId("All")
    }
    else {
      const teamIWant = stateTeams.filter((team) => team.name === activeTabTeam)
      const teamIDIwant = teamIWant[0].id
      setTeamId(teamIDIwant)
    }
    const teamSessionsIWant = allSessions.filter((team) => team.name === activeTabTeam)
    setIWantThis(teamSessionsIWant[0] !== undefined ? teamSessionsIWant[0] : [])

  }, [])

  //Each time activeTab changes, find the match for the stateTeams to get the ID
  useEffect(() => {

    if (activeTabTeam === "All Teams") {
      setTeamId("All")
      setUseAllTeams(true)
    }
    else {
      const teamIWant = stateTeams.filter((team) => team.name === activeTabTeam)
      const teamIDIwant = teamIWant[0].id
      setTeamId(teamIDIwant)
      setUseAllTeams(false)
    }
    const teamSessionsIWant = allSessions.filter((team) => team.name === activeTabTeam)
    setIWantThis(teamSessionsIWant[0] !== undefined ? teamSessionsIWant[0] : [])

  }, [stateTeams, activeTabTeam])

  const refreshTeamSessions = async () => {
    await fetchTeamSessions();
    await fetchAllSessions();
  };


  const fetchTeams = async () => {
    setIsLoading(true);
    setTeamsLoading(true);
    try {
      const response = await teamRequests.getTeams(schoolId, ageGroup);

      if (response.status === 200) {
        dispatch(setAvailableTeams(response.data));
      } else {
        setTeamsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching Teams:', error);
    } finally {
      setIsLoading(false);
      setTeamsLoading(false);
    }
  };


  const fetchTeamSessions = async () => {
    setIsLoading(true);
    setTeamSessionsLoading(true);
    try {
      if (teamId !== "All") {
        const response = await sessionRequests.getTeamSessions(teamId);

        if (response.status === 200) {
          setTeamSessions(response.data);

          if (response.weeklyFocusPoint !== null && stateWeeklyFocusPoint === "") {
            dispatch(setStateWeeklyFocusPoint(response.weeklyFocusPoint));
          }
        }
      }
    } catch (error) {
      console.error('Error fetching team sessions:', error);
    } finally {
      setIsLoading(false);
      setTeamSessionsLoading(false);
    }
  };


  // This is where we handle the complicated Array

  const fetchAllSessions = async () => {
    setAllSessionsLoading(true);
    setIsLoading(true);
    try {
      const response = await sessionRequests.getAllSessions(schoolId, ageGroup);

     
      if (response.status === 200) {
        setstateAllSessions(response.data);

        if (response.weeklyFocusPoint !== null && stateWeeklyFocusPoint === "") {
          dispatch(setStateWeeklyFocusPoint(response.weeklyFocusPoint));
        }
      }
    } catch (error) {
      console.error('Error fetching all sessions:', error);
    } finally {
      setIsLoading(false);
      setAllSessionsLoading(false);
    }
  };


  const focusPointArray = stateSessions.map((session) => {
    const { name, teamSessions: { todaySessions, weeklyFocusPoint } } = session;

    const newObject = {
      name,
      weeklyFocusPoint
    }
    return newObject

  }).flat();


  useEffect(() => {
    //Needs to fire immediately to let events be filtered.
    dispatch(filterEvents());
  }, [])

  let sessionObject = []

  useEffect(() => {


    sessionObject = {
      sessionType,
      time,
      splitValues,
    };

  }, [sessionType, time, splitValues]);

  async function getAllSessions() {
    setIsLoading(true)
    await fetchAllSessions();
    setIsLoading(false)
  }


  return (
    <div>
      {allSessionsLoading || teamsLoading || isLoading ? (
        <Loader />
      ) : (
        <Box component="main" style={{ marginRight: '-25px', marginLeft: '-25px', marginTop: '-48px', overflow: 'hidden' }}>
          <Toolbar />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-30px', marginBottom: '10px' }}>

                  {
                    retrievedWeeklyFocusPoint !== 'Non' ?
                      <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', }}>
                        <Typography variant='h6' >Weekly Focus Point: </Typography>
                        <Typography>{retrievedWeeklyFocusPoint !== '' ? retrievedWeeklyFocusPoint : 'No Focus Point'}
                          <Button style={{ marginLeft: '-20px', marginBottom: '10px' }}
                            onClick={() => { setIsWeeklyFocusPopupOpen(true) }}>
                            <EditIcon></EditIcon>
                          </Button>
                        </Typography>


                      </div>
                      :
                      <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                        <Button onClick={() => { setIsWeeklyFocusPopupOpen(true) }}>Set Focus point for the week</Button>
                        <br />
                      </div>
                  }
                </div>
                <TSession
                  weeklyFocusPoint={retrievedWeeklyFocusPoint}
                  sessions={useAllTeams ? allSessions : []}
                  team={iWantThis !== undefined ? iWantThis.name : ""}
                  todaySessions={iWantThis !== undefined && iWantThis.teamSessions !== undefined ? iWantThis.teamSessions.todaySessions : []}
                  refreshTeamSessions={refreshTeamSessions}
                />
              </Grid>
              <Grid item xs={12}>
                <FSessions
                  weeklyFocusPoint={retrievedWeeklyFocusPoint}
                  sessions={useAllTeams ? allSessions : []}
                  type={'session'}
                  team={iWantThis !== undefined ? iWantThis.name : ""}
                  futureSessions={iWantThis !== undefined && iWantThis.teamSessions !== undefined ? iWantThis.teamSessions.futureSessions : []}
                  refreshTeamSessions={refreshTeamSessions}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      <WeeklyFocusPoint
        isOpen={isWeeklyFocusPopupOpen}
        setFocusPointWindowOpen={setIsWeeklyFocusPopupOpen}
        activeTeam={activeTabTeam}
        getAllSessions={getAllSessions}
      >
      </WeeklyFocusPoint>
    </div>
  );
}


export default Session









// return (
//   <div>
//     {
//       isLoading ?
//         <Loader />
//         :
//         <Box component="main" style={{ marginRight: '-25px', marginLeft: '-25px', marginTop: '-48px', overflow: 'hidden' }}>
//           <Toolbar />
//           <Container maxWidth="lg">
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 {/* <TSession todaySessions={teamSessions.todaySessions !== undefined ? teamSessions.todaySessions : todaySessions} refreshTable={refreshTable} /> */}
//                 <TSession
//                   sessions={useAllTeams ? allSessions : []}
//                   team={iWantThis !== undefined ? iWantThis.name : ""}
//                   todaySessions={iWantThis !== undefined && iWantThis.teamSessions !== undefined ? iWantThis.teamSessions.todaySessions : []}
//                   refreshTeamSessions={refreshTeamSessions} />
//                 {/* <TSession todaySessions={teamSessions.todaySessions !== undefined ? teamSessions.todaySessions : todaySessions}
//                   refreshTeamSessions={refreshTeamSessions} // Pass the callback function as a prop
//                 /> */}
//               </Grid>
//               <Grid item xs={12}>
//                 {/* <FSessions type={'session'} futureSessions={teamSessions.futureSessions !== undefined ? teamSessions.futureSessions : futureSessions}
//                   refreshTeamSessions={refreshTeamSessions} // Pass the callback function as a prop
//                 /> */}

//                 {/* <FSessions
//                   type={'session'}
//                   team={iWantThis !== undefined ? iWantThis.name : ""}
//                   todaySessions={iWantThis !== undefined && iWantThis.teamSessions !== undefined ? iWantThis.teamSessions.futureSessions : []}
//                   refreshTeamSessions={refreshTeamSessions} /> */}

//                 <FSessions
//                   sessions={useAllTeams ? allSessions : []}
//                   type={'session'}
//                   team={iWantThis !== undefined ? iWantThis.name : ""}
//                   futureSessions={iWantThis !== undefined && iWantThis.teamSessions !== undefined ? iWantThis.teamSessions.futureSessions : []}
//                   refreshTeamSessions={refreshTeamSessions}
//                 />

//               </Grid>

//             </Grid>
//           </Container>
//         </Box>
//     }
//   </div>
// );
