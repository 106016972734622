import React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Content/title.js';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { playerRequests } from '../../services/api-requests.js';
import ConstructionIcon from '@mui/icons-material/Construction';
import PrintInjuredList from './printInjured.js';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function InjuredList(props) {
  // console.log('Props received in InjuredList: ', props);
  // console.log('Props received in InjuredList: ', props.players);
  // console.log('Props.statuses received in InjuredList: ', props.statuses);
  const [playerToBeAvailable, setPlayerToBeAvailable] = React.useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openInjuredSnackbar, setOpenInjuredSnackbar] = React.useState(false);
  const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [injuredMessage, setInjuredMessage] = React.useState('');
  const [injuredError, setInjuredError] = React.useState('');
  const [error, setError] = React.useState(false);
  const schoolId = parseInt(useSelector((state) => state.user.profile.school))

  const navigate = useNavigate()


  const maxPositions = Object.values(props.players).length > 0
    ? props.players.reduce((max, player) => Math.max(max, player.positionList.length), 0)
    : 0;

  async function handleClearOption(player) {
    setHandleDeletedClicked(true);
    setPlayerToBeAvailable(player);
    handleOpen();
  }
  async function setPlayerAsAvailable(player) {
    setHandleDeletedClicked(true);
    setPlayerToBeAvailable(player);
    handleOpen();
  }

  async function setPlayerAsAvailable(player) {

    const statusIWant = props.statuses.find((status) => status.status === "Available")
    // console.log('Status I Want: ', statusIWant)
    const statusId = statusIWant.id
    // console.log('StatusID I Want: ', statusId)
    // console.log('Player in available: ', player)
    try {

      // Perform the API call to update the player Availability
      const response = await playerRequests.UpdateStatus(player.id, statusId);

      if (response.status === 200) {
        // console.log('Response from Updating Status: ', response);
        setInjuredError(false)
        setInjuredMessage('Player set to Available. Click to refresh');
        setOpenInjuredSnackbar(true)
      } else {
        setInjuredMessage('Something went wrong');
        setInjuredError(true)
        setOpenInjuredSnackbar(true)
      }
    } catch (error) {
      console.error('error: ', error);
      setInjuredError(true)
      setOpenInjuredSnackbar(true)
      setInjuredMessage('Something went wrong');
    } finally {

    }

  }

  async function setPlayerAsRTP(player) {

    const statusIWant = props.statuses.find((status) => status.status === "RTP (Return to play protocol)")
    // console.log('Status I Want: ', statusIWant)
    const statusId = statusIWant.id
    // console.log('StatusID I Want: ', statusId)
    // console.log('Player in available: ', player)
    try {

      // Perform the API call to update the player Availability
      const response = await playerRequests.UpdateStatus(player.id, statusId);

      if (response.status === 200) {
        // console.log('Response from Updating Status to RTP: ', response);
        setInjuredError(false)
        setInjuredMessage('Player set to Return to Play. Click to refresh');
        setOpenInjuredSnackbar(true)
      } else {
        setInjuredMessage('Something went wrong');
        setInjuredError(true)
        setOpenInjuredSnackbar(true)
      }
    } catch (error) {
      console.error('error: ', error);
      setInjuredError(true)
      setOpenInjuredSnackbar(true)
      setInjuredMessage('Something went wrong');
    } finally {

    }

  }


  const handleCloseInjuredSnackbar = (event, reason) => {

    if (reason === 'clickaway') {
      //Add a refresh method to get the teams again
      props.refreshSquad()
      return;
    }
    setOpenSnackbar(false);
    props.refreshSquad()

  };

  const handleCloseSnackbar = (event, reason) => {

    if (reason === 'clickaway') {
      //Add a refresh method to get the teams again
      props.refreshSquad()
      return;
    }
    setOpenSnackbar(false);
    props.refreshSquad()

  };


  return (
    <React.Fragment>
      <PrintInjuredList players={props.players} />
      <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
        <Title>Injured Player List</Title>
        <div style={{ overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>Weight</TableCell>
               

                {Array.from({ length: maxPositions }, (_, index) => (
                  <TableCell key={index}>{`Position ${index + 1}`}</TableCell>
                ))}
                <TableCell colSpan={maxPositions}></TableCell>
                <TableCell>Date of Birth</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.players && Object.values(props.players).length !== 0 ? (
                props.players.map((player, playerIndex) => (
                  <TableRow key={playerIndex}>
                    <TableCell>{playerIndex + 1}</TableCell>
                    <TableCell>{player.firstName}</TableCell>
                    <TableCell>{player.lastName}</TableCell>
                    <TableCell>{player.height}</TableCell>
                    <TableCell>{player.weight}</TableCell>
                    {player.positionList.map((position, splitIndex) => (
                      <TableCell key={splitIndex}>{position}</TableCell>
                    ))}
                    <TableCell colSpan={maxPositions}></TableCell>
                    <TableCell>{player.dateOfBirth}</TableCell>
                    <TableCell colSpan={maxPositions} style={{ textAlign: 'right' }}>
                   

                      <Tooltip title="Edit Player Details"  >
                        <IconButton onClick={() => navigate(`/EditPlayer/${schoolId}/${player.id}`)} style={{ color: '#87CEFA' }}>
                          <EditIcon></EditIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Return to Play Protocol">
                        <IconButton onClick={() => setPlayerAsRTP(player)} style={{ color: 'gray' }}>
                          <ConstructionIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Set Player as Available">
                        <IconButton onClick={() => setPlayerAsAvailable(player)} style={{ color: 'green' }}>
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Player">

                        <IconButton onClick={() => handleClearOption(player)} style={{ color: 'red' }}>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>

                    </TableCell>
                  
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3 + maxPositions} align='right'>No injured players. Well Done!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Snackbar
            open={openInjuredSnackbar}
            // autoHideDuration={3000}
            onClose={handleCloseInjuredSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseInjuredSnackbar}
              severity={injuredError ? "error" : "success"}
            >
              {injuredMessage}
            </MuiAlert>
          </Snackbar>

          <Snackbar
            open={openSnackbar}
            // autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={error ? "error" : "success"}
            >
              {Message}
            </MuiAlert>
          </Snackbar>


        </div>
      </Paper>


    </React.Fragment>
  );
}

export default InjuredList;
