import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SchoolIcon from '@mui/icons-material/School';
import SportsIcon from '@mui/icons-material/Sports';
import CheckIcon from '@mui/icons-material/Check';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BrushIcon from '@mui/icons-material/Brush';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setProfile } from '../user/userSlice';
import { useState } from 'react';
import { useEffect } from 'react';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const dispatch = useDispatch();
  const devProfile = useSelector((state) => state.user.devProfile);
  const schoolId = useSelector((state) => state.user.profile.school);
  const userId = useSelector((state) => state.user.profile.id);
  const role = useSelector((state) => state.user.profile.role);
  // const extraFeaturesPaid = useSelector((state) => state.user.profile.extraFeatures);
  const subscriptionStatus = useSelector((state) => state.user.profile?.subscriptionStatus);
  const extraFeaturesPaid = true
  const [extraFeatures, setExtraFeatures] = useState(true);
  const [stateExtraFeatures, setStateExtraFeatures] = useState(false);

  let admin;
  // let extraFeatures
  role === 'Admin' ? admin = true : admin = false;


  useEffect(() => {

    if (subscriptionStatus === 'Extra Features') {

      setStateExtraFeatures(true)
      return;
    }
    if (subscriptionStatus === 'Trial - Extra Features') {

      setStateExtraFeatures(true)
      return;
    }
    else {

      setStateExtraFeatures(false)
      return;
    }
  }, [subscriptionStatus])



  const navigate = useNavigate();
  const { ...other } = props;

  const devCategories = [
    {
      children: [
        { id: 'Admin Dashboard', name: 'AdminDashboard', icon: <DashboardIcon />, active: true },
        { id: 'School Managers', name: 'Managers', icon: <AssignmentIndIcon />, active: false },
        { id: 'Schools', icon: <SchoolIcon />, active: false },
        { id: 'Coaches', icon: <SportsIcon />, active: false },
        { id: 'Approve Coaches', name: 'ApproveCoaches', icon: <CheckIcon />, active: false },
        { id: 'Players', icon: <PeopleIcon />, active: false },
        { id: 'Manage SC Team', name: 'ManageSCTeam', icon: <Diversity2Icon />, active: false },
        { id: 'Resource', icon: <ArticleIcon />, active: false },
        { id: 'Developer Settings', name: 'DeveloperSettings', icon: <SettingsIcon />, active: false },
        { id: 'Log Out', name: 'LogOut', icon: <LogoutIcon />, active: false },
      ],
    },
  ];

  const categories = [
    {
      children: [
        { id: 'Dashboard', icon: <DashboardIcon />, active: true },
        { id: 'GameDay', name: 'GameDay', icon: <SportsRugbyIcon />, active: false },
        { id: 'Teams', icon: <PeopleIcon />, active: false },
        { id: 'Stats', icon: <QueryStatsIcon />, active: false },
        { id: 'Calendar', icon: <CalendarMonthIcon />, active: false },
        { id: 'Sessions', icon: <DirectionsRunIcon />, active: false },
        { id: 'Season', icon: <EmojiEventsIcon />, active: false },
      ],
    },
  ];

  const bottomTabs = [
    {
      children: [
        { id: 'Resource', icon: <ArticleIcon />, active: false },
        { id: 'Settings', icon: <SettingsIcon />, active: false },
        { id: 'Log Out', name: 'LogOut', icon: <LogoutIcon />, active: false },
      ],
    },
  ];

  const extraFeaturesTabs = [
    {
      children: [
        { id: 'Draw and Share', name: 'DrawAndShare', icon: <BrushIcon />, active: false },
        { id: 'Testing System', name: 'TestingSystem', icon: <AssignmentIcon />, active: false },
        { id: 'Gym Sessions', name: 'Gym', icon: <FitnessCenterIcon />, active: false },
        { id: 'Opponent Game Analysis', name: 'OpponentGameAnalysis', icon: <SummarizeIcon />, active: false },
        { id: 'Session Review', name: 'Review', icon: <RateReviewIcon />, active: false },
        { id: 'Assign Roles', name: 'GamedayRoles', icon: <SupervisorAccountIcon />, active: false },
        { id: 'Rugby Program', name: 'RugbyProgram', icon: <PendingActionsIcon />, active: false },
        { id: 'Player Program', name: 'PlayerProgram', icon: <HistoryToggleOffIcon />, active: false },
      ],
    },
  ];

  function handleSubmit(childId, children) {
    sessionStorage.setItem('activePage', childId);
    window.dispatchEvent(new Event('storage'));

    const foundChild = children.filter((child) => child.id === childId);
    const notFoundChild = children.filter((child) => child.id !== childId);
    notFoundChild.forEach((child) => { child.active = false; });
    foundChild[0].active = true;
    const navigateToName = foundChild[0].name;


    if (navigateToName === 'LogOut') {
      navigate('/signIn');
      localStorage.setItem('token', '');
      dispatch(setProfile());

    } else if (navigateToName === 'Settings' && role !== 'Manager') {
      // Handle settings logic for non-manager
    } else if (navigateToName !== undefined) {
      navigate(`/${navigateToName}`);
    } else {
      if (childId === 'Settings' && role !== 'Manager') {
        navigate(`/Settings/${schoolId}/${userId}`);
      } else {
        navigate(`/${childId}`);
      }
    }
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Squad Control
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemText>Simplify. Organize. Succeed</ListItemText>
        </ListItem>
        {admin ? (
          <div>
            {devCategories.map(({ id, children }) => (
              <Box key={id} sx={{ bgcolor: '#101F33' }}>
                <ListItem sx={{ py: 2, px: 3 }}>
                  <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                </ListItem>
                {children.map(({ id: childId, icon, active }) => (
                  <ListItem disablePadding key={childId}>
                    <ListItemButton onClick={() => handleSubmit(childId, children)} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{childId === 'GameDay' ? 'Game Day' : childId}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
                <Divider sx={{ mt: 2 }} />
              </Box>
            ))}
          </div>
        ) : (
          <div>
            {categories.map(({ id, children }) => (
              <Box key={id} sx={{ bgcolor: '#101F33' }}>
                <ListItem sx={{ py: 2, px: 3 }}>
                  <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                </ListItem>
                {children.map(({ id: childId, icon, active }) => (
                  <ListItem disablePadding key={childId}>
                    <ListItemButton onClick={() => handleSubmit(childId, children)} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{childId === 'GameDay' ? 'Game Day' : childId}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
                <Divider sx={{ mt: 2 }} />
              </Box>
            ))}
          </div>
        )}
        {
          stateExtraFeatures ?
            <>
              <Accordion expanded={extraFeatures} sx={{ bgcolor: '#101F33', marginTop: '-10px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  onClick={() => setExtraFeatures(!extraFeatures)}
                  sx={{ bgcolor: '#101F33', margin: 0, marginBottom: '-10px' }}
                >
                  <ListItem sx={{}}>
                    <ListItemText sx={{ color: '#fff' }}>Extra Features</ListItemText>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#101F33', marginLeft: '-16px' }}>
                  {extraFeaturesTabs.map(({ id, children }) => (
                    <Box key={id}>
                      {children.map(({ id: childId, icon, active }) => (
                        <ListItem disablePadding key={childId}>
                          <ListItemButton onClick={() => handleSubmit(childId, children)} sx={item}>
                            <ListItemIcon >{icon}</ListItemIcon>
                            <ListItemText >{childId === 'GameDay' ? 'Game Day' : childId}</ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
            :
            <>
            </>
        }

        {bottomTabs.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton onClick={() => handleSubmit(childId, children)} sx={item}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
        <Box sx={{ mt: 'auto', p: 2 }}>
          <p style={{ color: 'grey', fontSize: '12px' }}>Version: 1.0.7</p>
        </Box>

      </List>
      <Outlet />
    </Drawer>
  );
}
