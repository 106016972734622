import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Scheduler } from "@aldabil/react-scheduler";
import EventView from './eventView';
import EditView from './editView';

import { calendarRequests } from '../../services/api-requests';
import './calendar.css';
import Loader from '../../layouts/loader';

function Calendar() {
  const calendarRef = useRef(null);
  const ageGroup = useSelector((state) => parseInt(state.user.profile.ageGroup))
  const schoolId = useSelector((state) => parseInt(state.user.profile.school))
  const profileAgeGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getEvents();
  }, [profileAgeGroup]);

  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    try {
      await calendarRequests.GetEvents(schoolId, ageGroup).then((response) => {
        if (response.status === 200) {
          setEvents(processEvents(response.data));

        }
        else {
          console.log("response =", response);
        }
      });
    }
    catch (err) {
      console.log("err =", err);
    }
    finally {
      setIsLoading(false);
    }
  };

  const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  }

  const processEvents = (events) => {
    return events.map((event) => {
      return {
        event_id: event.id,
        title: event.eventName,
        start: new Date(event.date),
        end: addMinutes(new Date(event.date), event.duration),
        allDay: false,
        disabled: false,
        editable: true,
        deletable: true,
        draggable: false,
      }
    });
  }

  const structureEventCreate = (event) => {
    return {
      SchoolId: schoolId,
      AgeGroup: ageGroup,
      EventName: event.title,
      CategoryId: 1,
      Date: event.start,
      Duration: (event.end - event.start) / 60000,
    }
  }

  const structureEventUpdate = (event) => {
    return {
      Id: event.event_id,
      EventName: event.title,
      CategoryId: 1,
      Date: event.start,
      Duration: (event.end - event.start) / 60000,
    }
  }

  const handleConfirm = async (event, action) => {
    return new Promise((res, rej) => {
      if (action === "edit") {
        setIsLoading(true)
        try {
          calendarRequests.UpdateEvent(structureEventUpdate(event)).then((response) => {
            if (response.status === 200) {
              setEvents(processEvents(response.data.value));
            }
            else {
              console.log("response =", response);
            }
          })
        } catch (error) {
          console.error("Error: ", error)
        } finally {
          setIsLoading(false)
        }

      }
      else if (action === "create") {
        console.log("event =", event);
        setIsLoading(true)
        try {
          calendarRequests.AddEvent(structureEventCreate(event)).then((response) => {
            if (response.status === 200) {
              setEvents(processEvents(response.data.value));
            }
            else {
              console.log("response =", response);
            }
          })
        } catch (error) {
          console.error("Error: ", error)
        }
        finally {
          setIsLoading(false)
        }

      }

      res({
        ...event,
        event_id: event.event_id
      });
    });
  };

  const handleDelete = async (deletedId) => {
    return new Promise((res, rej) => {
      setIsLoading(true)
      try {
        calendarRequests.DeleteEvent(deletedId).then((response) => {
          if (response.status === 200) {
            setEvents(processEvents(response.data.value));
          }
          else {
            console.log("response =", response);
          }
        })

      }
      catch (err) {
        console.log("err =", err);
      }
      finally {
        res(true);
        setIsLoading(false)
      }
    });
  };

  return (
    <>
      {
        isLoading ?
          <Loader />
          :
          <Scheduler
            ref={calendarRef}
            view="month"
            events={events}
            loading={isLoading}
            onConfirm={handleConfirm}
            day={null}
            onDelete={handleDelete}
            week={{
              weekDays: [0, 1, 2, 3, 4, 5],
              weekStartOn: 6,
              startHour: 8,
              endHour: 17,
              step: 60,
            }}
            customViewer={
              (props, close) =>
                <EventView {...props}
                  onClose={close}
                  onDelete={handleDelete}
                  schedulerRef={calendarRef.current ? calendarRef.current.scheduler : null}
                />
            }
            customEditor={
              (scheduler) =>
                <EditView
                  scheduler={scheduler}
                  onConfirm={handleConfirm}
                />
            }
          />
      }
    </>
  );
}


export default Calendar;
