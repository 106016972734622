import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import ToggleColorMode from './components/ToggleColorMode';
// import getMPTheme from './theme/getMPTheme';

export default function LandingPage() {
    const [mode, setMode] = React.useState('dark');
    const [showCustomTheme, setShowCustomTheme] = React.useState(false);
    // const MPTheme = createTheme(getMPTheme(mode));
    const defaultTheme = createTheme({ palette: { mode } });

    // This code only runs on the client side, to determine the system color preference
    React.useEffect(() => {
        // Check if there is a preferred mode in localStorage
        const savedMode = localStorage.getItem('themeMode');
        if (savedMode) {
            setMode(savedMode);
        } else {
            // If no preference is found, it uses system preference
            const systemPrefersDark = window.matchMedia(
                '(prefers-color-scheme: dark)',
            ).matches;
            setMode(systemPrefersDark ? 'dark' : 'light');
        }
    }, []);

    const toggleColorMode = () => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setMode(newMode);
        localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
    };

    const toggleCustomTheme = () => {
        setShowCustomTheme((prev) => !prev);
    };
    const MPTheme = ''

    return (
        <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
            <CssBaseline enableColorScheme />
            {/* AppBar component */}
            <AppAppBar />

            {/* Color mode toggle button in the top-right corner */}
            <div style={{ position: 'fixed', top: 10, right: 16 }}>
                <ToggleColorMode
                    mode={mode}
                    toggleColorMode={toggleColorMode}
                />
            </div>

            {/* Main content of the landing page */}
            <Hero />
            <div>
                {/* <LogoCollection /> */}
                <Features />
                {/* <Divider />
                <Testimonials /> */}
                <Divider />
                <Highlights />
                {/* <Divider />
                <Pricing /> */}
                <Divider />
                <FAQ />
                <Divider />
                <Footer />
            </div>
        </ThemeProvider>
    );
}
