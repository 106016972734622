import React from 'react';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { gameRequests } from '../../../services/api-requests';
import LogoSelector from '../../../services/logoSelector';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';


function GenerateHalfTimeReport(props) {
    console.log('Props received in report', props)
    const dispatch = useDispatch();
    const [schoolImage, setSchoolImage] = useState(null);
    const [opponentImage, setOpponentImage] = useState(null);
    const [error, setError] = useState(false);
    const [openNotification, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [additionalMessage, setAdditionalMessage] = useState('');

    const stateOpponent = useSelector((state) => state.gameday.opponent);
    const stateAgeGroup = useSelector((state) => state.gameday.ageGroup);
    const actions = useSelector((state) => state.gameday.stateActions);
    const opponent = useSelector((state) => state.gameday.opponent);
    const kickoffTime = useSelector((state) => state.gameday.kickoffTime);
    const activeTeam = useSelector((state) => state.gameday.activeTeam);
    const schoolName = useSelector((state) => state.user.schoolName);

    useEffect(() => {
        getImage();
        getOpponentImage(stateOpponent);
    }, [schoolName, stateOpponent]);

    const getImage = async () => {
        const image = await LogoSelector(schoolName);
        setSchoolImage(image);
    };

    const getOpponentImage = async (opponent) => {
        const image = await LogoSelector(opponent);
        setOpponentImage(image);
    };

    const handleCloseConfirmationSnackbar = (event, reason) => {
        setNotificationOpen(false)
        generateHalfTimeReport();
       
    };



    async function submitData() {

        console.log('Going to be working with props.events: ', props.events)
        console.log('Also working with the gameid: ', props.gameId)

        try {

            const response = await gameRequests.submitHalfTimeReport(props.gameId, props.events)

            if (response.status === 200) {
                setNotificationMessage('Report Data Submitted');
                setAdditionalMessage('Click to generate report');
                setError(false);
            }
            else {
                setNotificationMessage('Could not submit game data');
                setAdditionalMessage('Please try again');
                setError(true);

            }
        } catch (error) {
            console.log('Error: ', error)
        } finally {
            setNotificationOpen(true);
        }
    }


    async function generateHalfTimeReport() {
        const opponent = stateOpponent;
        const ageGroup = stateAgeGroup;

        const doc = new jsPDF();
        doc.setFontSize(12);

        const logoWidth = 60;
        const logoHeight = 50;
        doc.addImage(schoolImage, 'PNG', 10, 5, logoWidth, logoHeight);
        doc.addImage(opponentImage, 'PNG', 145, 5, logoWidth, logoHeight);

        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#000000');
        doc.setFontSize(16);
        doc.text(`${schoolName}`, 75, 20);
        doc.text(`vs`, 103, 30);
        doc.text(`${opponent}`, 75, 40);
        doc.setFontSize(10);
        doc.text(`${ageGroup} ${activeTeam}`, 103, 50);
        doc.setFontSize(14);
        doc.text(`Half time Report`, 86, 60);
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Venue: ${props.venue === 'Home' ? schoolName : opponent}`, 10, 70);
        doc.text(`Kickoff time: ${kickoffTime}`, 10, 80);
        doc.text(`-------------------------`, 10, 85);


        const categories = {
            'Attack': [],
            'Defence': [],
            'Lineouts': [],
            'Scrums': [],
            'Kick off receive': [],
            'Kick off': [],
            'Kicks': [],
            'Rucks': []
        };

        props.events.forEach(event => {
            if (categories[event.category]) {
                categories[event.category].push(event.description);
            }
        });

        let yPos = 95;
        Object.keys(categories).forEach(category => {
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            doc.text(category, 10, yPos);
            yPos += 7;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);
            categories[category].forEach(event => {
                doc.text(`• ${event}`, 15, yPos);
                yPos += 5;
            });
            yPos += 5;
        });

        doc.save(`half-time-report_${opponent}.pdf`);
    }

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button onClick={submitData} variant="outlined" style={{ marginBottom: '10px' }}>
                <HourglassBottomIcon /> Generate Half Time Report
            </Button>

            <Snackbar
                open={openNotification}
                autoHideDuration={2000}
                onClose={(event, reason) => handleCloseConfirmationSnackbar(event, reason)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={(event, reason) => handleCloseConfirmationSnackbar(event, reason)}
                    severity={error ? 'error' : 'success'}
                >
                    {notificationMessage}
                    <br />
                    {additionalMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default GenerateHalfTimeReport;
