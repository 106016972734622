import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Default MUI Grid
import { useTheme } from '@mui/system';
import EgJansenLogo from '../../../assets/EGJansenLogo.png'
import WaterkloofLogo from '../../../assets/kloof.jpeg'
import helpmekaar from '../../../assets/HelpMekaar.png'

const userTestimonials = [
    {
        avatar: <Avatar alt="Justice Nkombua"
            // src="/static/images/avatar/1.jpg" 
            src={helpmekaar}
        />,
        name: 'Justice Nkombua',
        occupation: 'Rugby Director at CBC Boksburg',
        testimonial:
            "I absolutely love how versatile this app is! As a new Rugby Director this app allows me to handle all of my day-to-day rugby tasks in a matter of minutes.",
    },
    {
        avatar: <Avatar alt="Rudi Dames"
            // src="/static/images/avatar/2.jpg" 
            src={EgJansenLogo}
            style={{ size: '15px' }}
        />,
        name: 'Rudi Dames',
        occupation: 'Rugby Director at Dr. EG Jansen',
        testimonial:
            "One of the standout features of this product is the easy of use. Simple, effective and fast!"
    },
    {
        avatar: <Avatar alt="Lukas Holtzhauzen"
            // src="/static/images/avatar/3.jpg" 
            src={WaterkloofLogo}
        />,
        name: 'Lukas Holtzhauzen',
        occupation: 'Rugby Director at Waterkloof',
        testimonial:
            'I can manage all my coaches with a few button clicks. The extra features take away so much of my work load!',
    },
    // {
    //     avatar: <Avatar alt="Remy Sharp" src="/static/images/avatar/4.jpg" />,
    //     name: 'Julia Stewart',
    //     occupation: 'Senior Engineer',
    //     testimonial:
    //         "I appreciate the attention to detail in the design of this product. The small touches make a big difference, and it's evident that the creators focused on delivering a premium experience.",
    // },
    // {
    //     avatar: <Avatar alt="Travis Howard" src="/static/images/avatar/5.jpg" />,
    //     name: 'John Smith',
    //     occupation: 'Product Designer',
    //     testimonial:
    //         "I've tried other similar products, but this one stands out for its innovative features. It's clear that the makers put a lot of thought into creating a solution that truly addresses user needs.",
    // },
    // {
    //     avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/6.jpg" />,
    //     name: 'Daniel Wolf',
    //     occupation: 'CDO',
    //     testimonial:
    //         "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last. Definitely worth the investment!",
    // },
];

const whiteLogos = [
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
    width: '64px',
    opacity: 0.3,
};

export default function Testimonials() {
    const theme = useTheme();
    const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

    return (
        <Container
            id="testimonials"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Typography
                    component="h2"
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'text.primary' }}
                >
                    Testimonials
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    See what other rugby coaches are saying about our services. Don't take our word for it, take theirs.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {userTestimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ color: 'text.secondary' }}
                                >
                                    {testimonial.testimonial}
                                </Typography>
                            </CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',

                                }}
                            >
                                <CardHeader

                                    avatar={testimonial.avatar}
                                    title={testimonial.name}
                                    subheader={testimonial.occupation}
                                />
                                {/* <img
                                    loading='lazy'
                                    src={logos[index]}
                                    alt={`Logo ${index + 1}`}
                                    style={logoStyle}
                                /> */}
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
