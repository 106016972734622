// import * as React from 'react';
// import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
// import MuiChip from '@mui/material/Chip';
// import Container from '@mui/material/Container';
// import Typography from '@mui/material/Typography';

// import { styled } from '@mui/material/styles';

// import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
// import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
// import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

// import TeamsImage from '../../../assets/LoginImages/Teams.png'
// import StatsImage from '../../../assets/LoginImages/Stats.png'
// import TestingSystemImage from '../../../assets/LoginImages/Testing System.png'

// const items = [
//     {
//         icon: <ViewQuiltRoundedIcon />,
//         title: 'Teams',
//         description:
//             "Drag and Drop your match day 23. Handle your entire squad's player status. Update Injury and Rehab States",
//         // imageLight: 'url(../../../assets/LoginImages/Teams.png)',
//         imageLight: TeamsImage,
//         //  imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
//         imageDark: TeamsImage,
//     },
//     {
//         icon: <EdgesensorHighRoundedIcon />,
//         title: 'Track all of your stats',
//         description:
//             'We track all of your stats. Provide the minimum, and leave the heavy lifting to us',
//         //imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
//         imageLight: StatsImage,
//         //  imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
//         imageDark: StatsImage,
//     },
//     {
//         icon: <DevicesRoundedIcon />,
//         title: 'Share Resources',
//         description:
//             'Share all your pdf and excel resources on one platform. Allowing all coaches to be on the same page at all times.',
//         //imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
//         imageLight: TeamsImage,
//         //  imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
//         imageDark: TeamsImage,
//     },
//     {
//         icon: <DevicesRoundedIcon />,
//         title: 'Enjoy a fully fledged testing system',
//         description:
//             'Make use of our standard testing system, or customize your own testing system. All at your fingertips.',
//         //imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
//         imageLight: TestingSystemImage,
//         //  imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
//         imageDark: TestingSystemImage,
//     },
// ];

// const Chip = styled(MuiChip)(({ theme }) => ({
//     variants: [
//         {
//             props: ({ selected }) => selected,
//             style: {
//                 background:
//                     'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
//                 color: 'hsl(0, 0%, 100%)',
//                 borderColor: theme.palette.primary.light,
//                 '& .MuiChip-label': {
//                     color: 'hsl(0, 0%, 100%)',
//                 },
//                 ...theme.applyStyles('dark', {
//                     borderColor: theme.palette.primary.dark,
//                 }),
//             },
//         },
//     ],
// }));

// function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
//     if (!items[selectedItemIndex]) {
//         return null;
//     }

//     return (
//         <Box
//             sx={{
//                 display: { xs: 'flex', sm: 'none' },
//                 flexDirection: 'column',
//                 gap: 2,
//             }}
//         >
//             <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
//                 {items.map(({ title }, index) => (
//                     <Chip
//                         size="medium"
//                         key={index}
//                         label={title}
//                         onClick={() => handleItemClick(index)}
//                         selected={selectedItemIndex === index}
//                     />
//                 ))}
//             </Box>
//             <Card variant="outlined">
//                 {/* <Box
//                     sx={(theme) => ({
//                         mb: 2,
//                         backgroundSize: 'cover',
//                         backgroundPosition: 'center',
//                         minHeight: 280,
//                         backgroundImage: 'var(--items-imageLight)',
//                         ...theme.applyStyles('dark', {
//                             backgroundImage: 'var(--items-imageDark)',
//                         }),
//                     })}
//                     style={
//                         items[selectedItemIndex]
//                             ? {
//                                 '--items-imageLight': items[selectedItemIndex].imageLight,
//                                 '--items-imageDark': items[selectedItemIndex].imageDark,
//                             }
//                             : {}
//                     }
//                 /> */}
//                 <Box
//                     sx={(theme) => ({
//                         mb: 2,
//                         backgroundSize: 'cover',
//                         backgroundPosition: 'center',
//                         minHeight: 280,
//                         backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
//                         ...theme.applyStyles('dark', {
//                             backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
//                         }),
//                     })}
//                 />

//                 <Box sx={{ px: 2, pb: 2 }}>
//                     <Typography
//                         gutterBottom
//                         sx={{ color: 'text.primary', fontWeight: 'medium' }}
//                     >
//                         {selectedFeature.title}
//                     </Typography>
//                     <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
//                         {selectedFeature.description}
//                     </Typography>
//                 </Box>
//             </Card>
//         </Box>
//     );
// }

// MobileLayout.propTypes = {
//     handleItemClick: PropTypes.func.isRequired,
//     selectedFeature: PropTypes.shape({
//         description: PropTypes.string.isRequired,
//         icon: PropTypes.element,
//         imageDark: PropTypes.string.isRequired,
//         imageLight: PropTypes.string.isRequired,
//         title: PropTypes.string.isRequired,
//     }).isRequired,
//     selectedItemIndex: PropTypes.number.isRequired,
// };

// export { MobileLayout };

// export default function Features() {
//     const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

//     const handleItemClick = (index) => {
//         setSelectedItemIndex(index);
//     };

//     const selectedFeature = items[selectedItemIndex];

//     return (
//         <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
//             <Box sx={{ width: { sm: '100%', md: '60%' } }}>
//                 <Typography
//                     component="h2"
//                     variant="h4"
//                     gutterBottom
//                     sx={{ color: 'text.primary' }}
//                 >
//                     Features
//                 </Typography>
//                 <Typography
//                     variant="body1"
//                     sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
//                 >
//                     From managing your coaches - to selecting your match day 23 - to analyzing your opponents games - we have it all!
//                 </Typography>
//             </Box>
//             <Box
//                 sx={{
//                     display: 'flex',
//                     flexDirection: { xs: 'column', md: 'row-reverse' },
//                     gap: 2,
//                 }}
//             >
//                 <div>
//                     <Box
//                         sx={{
//                             display: { xs: 'none', sm: 'flex' },
//                             flexDirection: 'column',
//                             gap: 2,
//                             height: '100%',
//                         }}
//                     >
//                         {items.map(({ icon, title, description }, index) => (
//                             <Box
//                                 key={index}
//                                 component={Button}
//                                 onClick={() => handleItemClick(index)}
//                                 sx={[
//                                     (theme) => ({
//                                         p: 2,
//                                         height: '100%',
//                                         width: '100%',
//                                         '&:hover': {
//                                             backgroundColor: theme.palette.action.hover,
//                                         },
//                                     }),
//                                     selectedItemIndex === index && {
//                                         backgroundColor: 'action.selected',
//                                     },
//                                 ]}
//                             >
//                                 <Box
//                                     sx={[
//                                         {
//                                             width: '100%',
//                                             display: 'flex',
//                                             flexDirection: 'column',
//                                             alignItems: 'left',
//                                             gap: 1,
//                                             textAlign: 'left',
//                                             textTransform: 'none',
//                                             color: 'text.secondary',
//                                         },
//                                         selectedItemIndex === index && {
//                                             color: 'text.primary',
//                                         },
//                                     ]}
//                                 >
//                                     {icon}

//                                     <Typography variant="h6">{title}</Typography>
//                                     <Typography variant="body2">{description}</Typography>
//                                 </Box>
//                             </Box>
//                         ))}
//                     </Box>
//                     <MobileLayout
//                         selectedItemIndex={selectedItemIndex}
//                         handleItemClick={handleItemClick}
//                         selectedFeature={selectedFeature}
//                     />
//                 </div>
//                 <Box
//                     sx={{
//                         display: { xs: 'none', sm: 'flex' },
//                         width: { xs: '100%', md: '70%' },
//                         height: 'var(--items-image-height)',
//                     }}
//                 >
//                     <Card
//                         variant="outlined"
//                         sx={{
//                             height: '100%',
//                             width: '100%',
//                             display: { xs: 'none', sm: 'flex' },
//                             pointerEvents: 'none',
//                         }}
//                     >
//                         {/* <Box
//                             sx={(theme) => ({
//                                 m: 'auto',
//                                 width: 420,
//                                 height: 500,
//                                 backgroundSize: 'contain',
//                                 backgroundImage: 'var(--items-imageLight)',
//                                 ...theme.applyStyles('dark', {
//                                     backgroundImage: 'var(--items-imageDark)',
//                                 }),
//                             })}
//                             style={
//                                 items[selectedItemIndex]
//                                     ? {
//                                         '--items-imageLight': items[selectedItemIndex].imageLight,
//                                         '--items-imageDark': items[selectedItemIndex].imageDark,
//                                     }
//                                     : {}
//                             }
//                         /> */}
//                         <Box
//                             sx={(theme) => ({
//                                 mb: 2,
//                                 backgroundSize: 'cover',
//                                 backgroundPosition: 'center',
//                                 minHeight: 280,
//                                 backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
//                                 ...theme.applyStyles('dark', {
//                                     backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
//                                 }),
//                             })}
//                         />
//                     </Card>
//                 </Box>
//             </Box>
//         </Container>
//     );
// }


/////////NEW COMPONENT

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

import TeamsImage from '../../../assets/Teams2.png';
// import StatsImage from '../../../assets/Stats.png';
// import TestingSystemImage from '../../../assets/TestingSystem.png';
// import ResourcesImage from '../../../assets/Resources.png'

const StatsImage = 'https://squadcontrolblobstorage.blob.core.windows.net/images/Stats.png';
const TestingSystemImage = 'https://squadcontrolblobstorage.blob.core.windows.net/images/TestingSystem.png';
const ResourcesImage = 'https://squadcontrolblobstorage.blob.core.windows.net/images/Resources.png'

const items = [
    {
        icon: <ViewQuiltRoundedIcon />,
        title: 'Teams',
        description:
            "Drag and Drop your match day 23. Handle your entire squad's player status. Update Injury and Rehab States",
        imageLight: TeamsImage,
        imageDark: TeamsImage,
    },
    {
        icon: <EdgesensorHighRoundedIcon />,
        title: 'Track all of your stats',
        description:
            'We track all of your stats. Provide the minimum, and leave the heavy lifting to us',
        imageLight: StatsImage,
        imageDark: StatsImage,
    },
    {
        icon: <DevicesRoundedIcon />,
        title: 'Share Resources',
        description:
            'Share all your pdf and excel resources on one platform. Allowing all coaches to be on the same page at all times.',
        imageLight: ResourcesImage,
        imageDark: ResourcesImage,
    },
    {
        icon: <DevicesRoundedIcon />,
        title: 'Enjoy a fully fledged testing system',
        description:
            'Make use of our standard testing system, or customize your own testing system. All at your fingertips.',
        imageLight: TestingSystemImage,
        imageDark: TestingSystemImage,
    },
];

const Chip = styled(MuiChip)(({ theme }) => ({
    variants: [
        {
            props: ({ selected }) => selected,
            style: {
                background:
                    'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
                color: 'hsl(0, 0%, 100%)',
                borderColor: theme.palette.primary.light,
                '& .MuiChip-label': {
                    color: 'hsl(0, 0%, 100%)',
                },
                ...theme.applyStyles('dark', {
                    borderColor: theme.palette.primary.dark,
                }),
            },
        },
    ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
    const theme = useTheme();

    if (!items[selectedItemIndex]) {
        return null;
    }

    return (
        <Box
            sx={{
                display: { xs: 'flex', sm: 'none' },
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
                {items.map(({ title }, index) => (
                    <Chip
                        size="medium"
                        key={index}
                        label={title}
                        onClick={() => handleItemClick(index)}
                        selected={selectedItemIndex === index}
                    />
                ))}
            </Box>
            <Card variant="outlined">
                <Box
                    sx={{
                        mb: 2,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: 280,
                        backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
                        ...(theme.palette.mode === 'dark' && {
                            backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
                        }),
                    }}
                />
                <Box sx={{ px: 2, pb: 2 }}>
                    <Typography
                        gutterBottom
                        sx={{ color: 'text.primary', fontWeight: 'medium' }}
                    >
                        {selectedFeature.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
                        {selectedFeature.description}
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
}

MobileLayout.propTypes = {
    handleItemClick: PropTypes.func.isRequired,
    selectedFeature: PropTypes.shape({
        description: PropTypes.string.isRequired,
        icon: PropTypes.element,
        imageDark: PropTypes.string.isRequired,
        imageLight: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function Features() {
    const theme = useTheme();
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[selectedItemIndex];

    return (
        <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
            <Box sx={{ width: { sm: '100%', md: '60%' } }}>
                <Typography
                    component="h2"
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'text.primary' }}
                >
                    Features
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
                >
                    From managing your coaches - to selecting your match day 23 - to analyzing your opponents games - we have it all!
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row-reverse' },
                    gap: 2,
                }}
            >
                <div>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            gap: 2,
                            height: '100%',
                        }}
                    >
                        {items.map(({ icon, title, description }, index) => (
                            <Box
                                key={index}
                                component={Button}
                                onClick={() => handleItemClick(index)}
                                sx={[
                                    {
                                        p: 2,
                                        height: '100%',
                                        width: '100%',
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                    },
                                    selectedItemIndex === index && {
                                        backgroundColor: theme.palette.action.selected,
                                    },
                                ]}
                            >
                                <Box
                                    sx={[
                                        {
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'left',
                                            gap: 1,
                                            textAlign: 'left',
                                            textTransform: 'none',
                                            color: theme.palette.text.secondary,
                                        },
                                        selectedItemIndex === index && {
                                            color: theme.palette.text.primary,
                                        },
                                    ]}
                                >
                                    {icon}

                                    <Typography variant="h6">{title}</Typography>
                                    <Typography variant="body2">{description}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <MobileLayout
                        selectedItemIndex={selectedItemIndex}
                        handleItemClick={handleItemClick}
                        selectedFeature={selectedFeature}
                    />
                </div>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        width: { xs: '100%', md: '70%' },
                        height: 'var(--items-image-height)',
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
                                ...(theme.palette.mode === 'dark' && {
                                    backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
                                }),
                            }}
                        />
                    </Card>
                </Box>
            </Box>
        </Container>
    );
}


