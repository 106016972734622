import React, { useState } from 'react';
import jsPDF from 'jspdf';
import { Button } from '@mui/material';
import logo from '../../assets/waterkloof logo.png'
import { useSelector } from 'react-redux';
import LogoSelector from '../../services/logoSelector';
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import SquadControlLogoWhite from '../../assets/SquadControlWhite.png'

function PrintSessions({ type, sessions, activeArray, weeklyFocusPoint }) {

    const [clicked, setClicked] = useState(false)
    const [used, setUsed] = useState(false)
    const [warmup, setWarmup] = useState(true)
    const schoolName = useSelector((state) => state.user.schoolName)
    const ageGroup = useSelector((state) => state.user.profile.ageGroup)
    const activeTabTeam = useSelector((state) => state.session.activeSelectedTeam)

    const maxNumSplits = sessions.reduce((max, session) => {
        // Check if session.splits is defined before accessing its length
        return Math.max(max, session.splits ? session.splits.length : 0);
    }, 0);

    /////////////
    // New
    const generateHighLevelPDF = async () => {
        const doc = new jsPDF('landscape'); // Create a landscape-oriented PDF

        const sessionsByDate = {};


        // Group sessions by date
        sessions.forEach((session) => {
            const date = (session.date || new Date()).toString().substring(0, 16);
            if (!sessionsByDate[date]) {
                sessionsByDate[date] = [];
            }
            sessionsByDate[date].push(session);

           
            // Update the warmup state based on session.warmup
            if (session.warmup === false) {
                setWarmup(false);
            }
            else {
                setWarmup(true);
            }
        });

        // Define the position and size of the logo
        const logoX = 10;
        const logoY = 10;
        const logoWidth = 50;
        const logoHeight = 50;

        const helperX = 120;
        const headerX = 110
        const headerY = logoY + logoHeight + 10;

        const SCLogoWidth = 70;
        const SCLogoHeight = 80;

        const mySchoolLogo = await LogoSelector(schoolName);
        doc.addImage(mySchoolLogo, 'PNG', 20, 10, logoWidth, logoHeight);

        // Add Extra data
        doc.setFontSize(12);
        doc.text(`Training Sessions`, helperX + 8, 20);
        doc.text(`${schoolName}`, helperX, 30);
        doc.text(`u/${ageGroup}`, helperX + 20, 40);
        doc.text(`${activeArray === 'All' ? 'All Teams' : activeTabTeam}`, helperX + 15, 50);

        doc.addImage(SquadControlLogoWhite, 'PNG', 220, 0, SCLogoWidth, SCLogoHeight);

        let currentY = logoY + logoHeight + 10; // Initial Y-coordinate for the first table



        // Display a message if there are no sessions
        if (Object.keys(sessionsByDate).length === 0) {
            doc.setFontSize(18);
            doc.text('There are no sessions', headerX + 4, currentY + 20);


            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);

            doc.text(`Copyright © Squad Control`, headerX + 15, doc.internal.pageSize.height - 10);
            doc.save(`high-level-sessions-for-${type}.pdf`);
            return;
        }
        else {
            // Add the date as a header
            doc.setFontSize(16);
            doc.text(`High-Level Report for ${type}`, headerX, currentY);
            //And then carry on with the rest of it
        }

        // Iterate through sessions grouped by date
        for (const [date, sessionsOnDate] of Object.entries(sessionsByDate)) {

           
            doc.setFontSize(12);
            doc.text(`Date: ${date}`, 15, currentY + 10);
            doc.text(`Weekly Focus Point:`, 231, currentY + 10);
            doc.text(weeklyFocusPoint, 231, currentY + 15);
            // Create a table for high-level format
            const table = [];
            const header = [activeArray === 'All' ? 'Team' : '', 'Session Type', 'Time', warmup ? 'Warm up' : 'Warm up', 'Duration', 'Aim of Session', 'Equipment Needed', 'Focus Points for session', 'Extra Notes']

            // Calculate the maximum number of splits across all sessions on this date
            // const maxNumSplits = sessionsOnDate.reduce(
            //     (max, session) => Math.max(max, session.splits.length),
            //     0
            // );

            const maxNumSplits = sessionsOnDate.reduce(
                (max, session) => {
                    const sessionSplitsLength = session.splits ? session.splits.length : 0;
                    return Math.max(max, sessionSplitsLength);
                },
                0
            );

           


            for (let i = 1; i <= maxNumSplits; i++) {
                header.push(`Split ${i}`);
                if (i < maxNumSplits) {
                    header.push('Water');
                }
            }

            table.push(header);

            sessionsOnDate.forEach((session) => {
                const row = [
                    activeArray === 'All' && session.name ? session.name : '',
                    session.category || 'Needs to be Edited',
                    session.DateTime || session.time,
                    session.warmUp ? '10 min' : 'No',
                    `${session.duration} min`, // Use the session's actual duration
                    session.aims || '', // Placeholder for 'Aim of Session'
                    session.equipment || '', // Placeholder for 'Equipment Needed'
                    session.focusPoint || '', // Placeholder for 'Focus Points for session'
                    session.extraNotes || '' // Data for 'Extra Notes'
                ];

                if (session.splits && session.splits.length > 0) {

                    // Add values for "Split" columns
                    session.splits.forEach((splitValue) => {
                        row.push(
                            // splitValue !== undefined ? `${splitValue} - ${timePerSplitDisplay}` : ''
                            splitValue !== undefined ? `${splitValue.type} - ${splitValue.duration} min ${splitValue.coach !== 'None' ? '-' : ''} ${splitValue.coach !== 'None' ? splitValue.coach : ''}` : ''
                        );
                        if (splitValue !== undefined) {
                            row.push('2 min'); // Add "Waterbreak" value after each "Split" except the last one
                        }
                    });
                } else {
                    // Add empty values for "Split" columns if no splits
                    for (let index = 0; index < maxNumSplits * 2 - 1; index++) {
                        row.push('');
                    }
                }

                table.push(row);

            });

            // const tableOptions = {
            //     // startY: yPos + tableData.length * 10 + 10, // Vertical position of the table
            //     startY: yPos,
            //     styles: { fontSize: 10, cellPadding: 5, valign: 'middle', halign: 'center' },
            //     headStyles: { fillColor: '#050505' },
            // };

            // Define the footer content
            const footerContent = function (data) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(10);
                doc.text(`Copyright © Squad Control`, headerX + 15, doc.internal.pageSize.height - 10);
            };

            const tableOptionsWithFooter = {
                addPageContent: footerContent,
                startX: 10, // Adjust the starting X-coordinate for the table
                margin: { left: 1, right: 1 }, // Adjust the margin for the table
            };

            doc.autoTable({
                head: [table[0]],
                body: table.slice(1),
                ...tableOptionsWithFooter,
                startY: currentY + 20, // Set the Y-coordinate for the table with some space
            });

            // Increase the current Y-coordinate for the next table
            currentY = doc.autoTable.previous.finalY + 10;
        }

        doc.save(`high-level-sessions-for-${type}.pdf`);
    };


    const generateLowLevelPDF = async () => {
        const doc = new jsPDF();
        const sessionsByDate = {};

        // Group sessions by date
        sessions.forEach((session) => {
            const date = (session.date || new Date()).toString().substring(0, 16);

            if (!sessionsByDate[date]) {
                sessionsByDate[date] = [];
            }
            sessionsByDate[date].push(session);

            // Update the warmup state based on session.warmup
            if (session.warmup === false) {
                setWarmup(false);
            }
        });

        // Define the position and size of the logo
        const logoX = 10; // X-coordinate of the logo
        const logoY = 10; // Y-coordinate of the logo
        const logoWidth = 50; // Width of the logo (adjust as needed)
        const logoHeight = 50; // Height of the logo (adjust as needed)

        const helperX = 78; // X-coordinate of the header and table
        const headerX = 73; // X-coordinate of the header and table
        const headerY = logoY + logoHeight + 10; // Y-coordinate of the header (below the logo)
        const tableY = headerY + 20; // Y-coordinate of the table (below the header)

        const SCLogoWidth = 70
        const SCLogoHeight = 80
        // Add the logo to the PDF
        // doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        const mySchoolLogo = await LogoSelector(schoolName)
        doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add Extra data
        doc.setFontSize(12);
        doc.text(`Training Sessions`, helperX + 8, 20);
        doc.text(`${schoolName}`, helperX, 30);
        doc.text(`u/${ageGroup}`, helperX + 20, 40);
        doc.text(`${activeArray === 'All' ? 'All Teams' : activeTabTeam}`, helperX + 15, 50);


        doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);


        let currentY = logoY + logoHeight + 20; // Initial Y-coordinate for the first table

        // Display a message if there are no sessions
        if (Object.keys(sessionsByDate).length === 0) {
            doc.setFontSize(18);
            doc.text('There are no sessions', headerX - 2, currentY + 20);

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);
            doc.text(`Copyright © Squad Control`, 85, doc.internal.pageSize.height - 10);
            doc.save(`low-level-sessions-for-${type}.pdf`);
            return;
        }
        else {
            doc.setFontSize(16);
            doc.text(`Player Report for ${type}`, headerX, currentY);
        }

        // Iterate through sessions grouped by date
        for (const [date, sessionsOnDate] of Object.entries(sessionsByDate)) {

            doc.setFontSize(12);
            doc.text(`Date: ${date}`, 15, currentY + 10);
            doc.text(`Weekly Focus Point:`, 148, currentY + 10);
            doc.text(weeklyFocusPoint, 148, currentY + 15);

            // Create a table for low-level format
            const table = [];
            const header = [activeArray === 'All' ? 'Team' : '', 'Session Type', 'Time', 'Duration', ...Array.from({ length: maxNumSplits }, (_, index) => `Split ${index + 1}`)];
            table.push(header);

            sessionsOnDate.forEach((session) => {
                const row = [
                    activeArray === 'All' && session.name ? session.name : '',
                    session.category || 'Needs to be Edited',
                    session.DateTime || session.time,
                    session.duration || '',
                ];
                if (session.splits && session.splits.length > 0) {

                    // Add values for "Split" columns
                    session.splits.forEach((splitValue) => {
                        row.push(
                            // splitValue !== undefined ? `${splitValue} - ${timePerSplitDisplay}` : ''
                            splitValue !== undefined ? `${splitValue.type}` : ''
                        );

                    });
                } else {
                    // Add empty values for "Split" columns if no splits
                    for (let index = 0; index < maxNumSplits * 2 - 1; index++) {
                        row.push('');
                    }
                }

                table.push(row);
            });

            // Define the footer content
            const footerContent = function (data) {
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(10);
                doc.text(`Copyright © Squad Control`, 85, doc.internal.pageSize.height - 10);
            };

            const tableOptionsWithFooter = {
                addPageContent: footerContent,
            };

            doc.autoTable({
                head: [table[0]],
                body: table.slice(1),
                ...tableOptionsWithFooter,
                startY: currentY + 20, // Set the Y-coordinate for the table with some space
            });

            // Increase the current Y-coordinate for the next table
            currentY = doc.autoTable.previous.finalY + 10;
        }

        doc.save(`low-level-sessions-for-${type}.pdf`);
    };




    const generateBothPDFs = () => {
        setClicked(true)
        generateHighLevelPDF();
        generateLowLevelPDF();
    };

    return (
        <div>
            <Button variant="contained" onClick={generateBothPDFs}>
                Print {type === 'Future' ? "Future" : "Today"} Sessions
            </Button>
        </div>
    );
}

export default PrintSessions;


// if (activeArray !== undefined && activeArray === 'All') {
//     updatedSessionsArray = sessions
//         .map((session) => {
//             const { name, teamSessions } = session;

//             // Check if teamSessions and todaySessions exist
//             if (teamSessions && teamSessions.todaySessions && teamSessions.todaySessions.length > 0) {
//                 // Flatten todaySessions and include name in the object
//                 const flattenedSessions = teamSessions.todaySessions.map((todaySession) => ({
//                     name,
//                     ...todaySession,
//                 }));

//                 return flattenedSessions;
//             }

//             // If teamSessions or todaySessions is missing or empty, return an empty array
//             return [];
//         })
//         .flat();

//     if (type === "Today") {
//         maxNumSplits = updatedSessionsArray.reduce((max, session) => Math.max(max, session.splits.length), 0);
//     }
//     console.log('MaxNumSplits when its ALL Teams: ', maxNumSplits);
// } else {

//     console.log('MaxNumSplits when its one team: ', maxNumSplits);
// }



////////////

// const generateHighLevelPDF = async () => {
//     console.log('Sessions in high level: ', sessions)
//     const doc = new jsPDF('landscape'); // Create a landscape-oriented PDF

//     const sessionsByDate = {};

//     console.log("TYPE: ", type)

//     // Group sessions by date
//     sessions.forEach((session) => {

//         const date = (session.date || new Date()).toString().substring(0, 16);
//         if (!sessionsByDate[date]) {
//             sessionsByDate[date] = [];
//         }
//         sessionsByDate[date].push(session);


//         // Update the warmup state based on session.warmup
//         if (session.warmup === false) {
//             setWarmup(false);
//         }
//     });

//     // Define the position and size of the logo
//     const logoX = 10; // X-coordinate of the logo
//     const logoY = 10; // Y-coordinate of the logo
//     const logoWidth = 50; // Width of the logo (adjust as needed)
//     const logoHeight = 50; // Height of the logo (adjust as needed)


//     const helperX = 120; // X-coordinate of the header and table
//     const headerX = 110
//     const headerY = logoY + logoHeight + 10; // Y-coordinate of the header (below the logo)
//     const tableY = headerY + 20; // Y-coordinate of the table (below the header)


//     const SCLogoWidth = 70
//     const SCLogoHeight = 80
//     // Add the logo to the PDF
//     // doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

//     const mySchoolLogo = await LogoSelector(schoolName)
//     doc.addImage(mySchoolLogo, 'PNG', 20, 10, logoWidth, logoHeight);

//     // Add Extra data
//     doc.setFontSize(12);
//     doc.text(`Training Sessions`, helperX + 8, 20);
//     doc.text(`${schoolName}`, helperX, 30);
//     doc.text(`u/${ageGroup}`, helperX + 20, 40);
//     doc.text(`${activeArray === 'All' ? 'All Teams' : 'A Team'}`, helperX + 17, 50);


//     doc.addImage(SquadControlLogoWhite, 'PNG', 220, 0, SCLogoWidth, SCLogoHeight);


//     let currentY = logoY + logoHeight + 10; // Initial Y-coordinate for the first table

//     // Add the date as a header
//     doc.setFontSize(16);
//     doc.text(`High-Level Report for ${type}`, headerX, currentY);

//     // Iterate through sessions grouped by date
//     for (const [date, sessionsOnDate] of Object.entries(sessionsByDate)) {

//         console.log('warmup? ', warmup)
//         doc.setFontSize(12);
//         doc.text(`Date: ${date}`, 15, currentY + 10);
//         // Create a table for high-level format
//         const table = [];
//         const header = ['Session Type', 'Time', warmup ? 'Warm up' : 'Warm up', 'Duration']

//         // Calculate the maximum number of splits across all sessions on this date
//         const maxNumSplits = sessionsOnDate.reduce(
//             (max, session) => Math.max(max, session.splits.length),
//             0
//         );

//         for (let i = 1; i <= maxNumSplits; i++) {
//             header.push(`Split ${i}`);
//             if (i < maxNumSplits) {
//                 header.push('Water');
//             }
//         }

//         table.push(header);

//         sessionsOnDate.forEach((session) => {
//             const row = [

//                 session.category || 'Needs to be Edited',
//                 session.DateTime || session.time,
//                 session.warmup ? '10' : 'No',
//                 `${session.duration} min`, // Use the session's actual duration
//             ];

//             if (session.splits && session.splits.length > 0) {

//                 // Add values for "Split" columns
//                 session.splits.forEach((splitValue) => {
//                     row.push(
//                         // splitValue !== undefined ? `${splitValue} - ${timePerSplitDisplay}` : ''
//                         splitValue !== undefined ? `${splitValue.type} - ${splitValue.duration} min ${splitValue.coach !== 'None' ? '-' : ''} ${splitValue.coach !== 'None' ? splitValue.coach : ''}` : ''
//                     );
//                     if (splitValue !== undefined) {
//                         row.push('2 min'); // Add "Waterbreak" value after each "Split" except the last one
//                     }
//                 });
//             } else {
//                 // Add empty values for "Split" columns if no splits
//                 for (let index = 0; index < maxNumSplits * 2 - 1; index++) {
//                     row.push('');
//                 }
//             }

//             table.push(row);
//         });

//         doc.autoTable({
//             head: [table[0]],
//             body: table.slice(1),
//             startY: currentY + 20, // Set the Y-coordinate for the table with some space
//         });

//         // Increase the current Y-coordinate for the next table
//         currentY = doc.autoTable.previous.finalY + 10;
//     }

//     doc.save(`high-level-sessions-for-${type}.pdf`);
// };







// const generateLowLevelPDF = () => {
//     const doc = new jsPDF();

//     // Define the position and size of the logo
//     const logoX = 10; // X-coordinate of the logo
//     const logoY = 10; // Y-coordinate of the logo
//     const logoWidth = 50; // Width of the logo (adjust as needed)
//     const logoHeight = 50; // Height of the logo (adjust as needed)

//     // Add the logo to the PDF
//     doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

//     // Set the position for the header and table
//     const headerX = 70; // X-coordinate of the header and table
//     const headerY = logoY + logoHeight + 10; // Y-coordinate of the header (below the logo)
//     const tableY = headerY + 10; // Y-coordinate of the table (below the header)


//     doc.text(`Low-Level Report for ${type}`, headerX, headerY);

//     // Create a table for low-level format
//     const table = [];
//     const header = ['Session Type', 'Time', ...Array.from({ length: maxNumSplits }, (_, index) => `Split ${index + 1}`)];
//     table.push(header);

//     sessions.forEach((session) => {
//         const row = [
//             session.category || 'Needs to be Edited',
//             session.DateTime || session.time,
//             ...session.splits,
//         ];
//         table.push(row);
//     });

//     doc.autoTable({
//         head: [table[0]],
//         body: table.slice(1),
//         startY: tableY, // Set the Y-coordinate for the table
//     });

//     doc.save(`low-level-sessions-for-${type}.pdf`);
// };