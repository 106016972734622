import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    error: null,
    userLoggedIn: false,
    profileCreated: false,
    devProfile: false,
    manager: false,
    customTestingSystem: {
        functionalTests: [],
        bodyTests: [],
        positionSpecificTests: {}
    },
    allCustomTestingSystems: [],
    saruTestingSystem: [],
    saruTestResults: [],
    customTestResults: [],
    createActive: false,
    schoolName: '',
    profile: {},
    token: '',
    approved: false,
    role: "",
    playersUploaded: false,
}

let response

// Basic Crud functionality
export const fetchSites = createAsyncThunk('site/endpointName', async () => {
    // const response = await client.get('/api/endpointIamLookingFor', null)
    const response = {};
    return response.data
})

export const addSite = createAsyncThunk('site/endpointName', async (extraData) => {
    // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
    const response = {};
    return response.data
})


export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
    // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
    const response = {};
    return extraData.body
})


export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
    // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
    const response = {};
    return response.data
})


export const testingSlice = createSlice({
    name: 'testingSystem',
    initialState,
    reducers: {
        setCustomTestingSystem: (state, action) => {
            console.log('Payload Received: ', action.payload)
            state.customTestingSystem = action.payload
        },
        setAllCustomTestingSystems: (state, action) => {
            console.log('Payload Received: ', action.payload)
            state.allCustomTestingSystems = action.payload
        },
        setSaruTestingSystem: (state, action) => {
            state.saruTestingSystem = action.payload
        },
        setCreateActive: (state, action) => {
            state.createActive = action.payload
        },
        setSaruStateTestResults: (state, action) => {
            state.saruTestResults = action.payload
        },
        setCustomStateTestResults: (state, action) => {
            state.customTestResults = action.payload
        }
    }
})

//Selectors come below:
export const { setCustomTestingSystem, setCustomStateTestResults, setSaruTestingSystem, setAllCustomTestingSystems, setCreateActive, setSaruStateTestResults } = testingSlice.actions

export default testingSlice.reducer