import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import SquadControlLogo from '../assets/Squad Control Logo New.jpeg'


const ErrorComponent = () => {
    return (
        <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '100px', }}>
            <img
                draggable="false"
                loading='lazy'
                style={{
                    marginLeft: '37%',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    height: '180px',
                    display: 'block',
                    marginX: 'auto',
                }}
                src={SquadControlLogo}
                alt="Squad Control Logo"
            />


            <Typography variant="h3">Something Went Wrong</Typography>
            <Typography variant="h5">Page not found</Typography>
            <Typography variant="body1">If the error continues, please report the bug from within the app</Typography>
            <Button component={Link} to="/dashboard" variant="contained" sx={{ marginTop: '20px' }}>
                Go to Dashboard
            </Button>
        </Container>
    );
};

export default ErrorComponent;
