import React, { useState, useEffect, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Player } from "./player"
import { Button, Input } from '@mui/material';
import AddPlayer, { AddPlayerModal } from './newTeam/addPlayer'
import { useSelector } from 'react-redux';
import { teamRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { Box, Paper } from '@mui/material';
import { Container, Grid, Tooltip } from '@mui/material';
import { Typography } from '@mui/material';
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from '../../layouts/errorComponent';
import EditIcon from '@mui/icons-material/Edit';
import { MyButton } from './newTeam/newButton';


const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function TeamList(props) {
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [isAddPlayerPopupOpen, setIsAddPlayerPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup));
  const schoolId = parseInt(useSelector((state) => state.user.profile.school))
  const [teamToBeDeleted, setTeamToBeDeleted] = useState('')
  const [teamToBeEdited, setTeamToBeEdited] = useState('')
  const [teamIdToBeEdited, setTeamIdToBeEdited] = useState(0)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);


  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditOpen = () => setEditModalOpen(true);
  const handleEditClose = () => setEditModalOpen(false);
  const [handleDeletedClicked, setHandleDeletedClicked] = useState(false);
  const availableTeams = useSelector((state) => state.team.availableTeams)

  //Edit Team Modal
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [newTeamName, setTeamName] = useState('');

  const addPlayerClick = (item) => {
    setShowAddPlayerModal(true)
  }

  useEffect(() => {
    console.log('value is: ', showNewTeamModal)
  }, [showNewTeamModal])


  async function onAddPlayer(playerObject) {
    let positionList = [0, 0];
    //Convert positions into positionList with IDs
    if (Object.values(playerObject).length !== 0 && playerObject.playerName !== '') {
      //Handle the postions 
      positionList[0] = playerObject.position;
      positionList[1] = playerObject.secondaryPosition;

      //Craft object to send over for service call
      const ReadyPlayerObject = {
        FirstName: playerObject.playerName,
        LastName: playerObject.playerLastName,
        Weight: parseInt(playerObject.weight),
        Height: parseInt(playerObject.height),
        PositionList: positionList,
        dateOfBirth: playerObject.dateOfBirth
      }


      try {
        props.setLoading(true)
        const response = await teamRequests.addPlayers(schoolId, ageGroup, [ReadyPlayerObject])
        if (response.status === 200) {

          props.showNotification('Player Added', false)
          props.setLoading(false)

          setError(false)
          setMessage('Player(s) added');
          setOpenSnackbar(true);

        }
      } catch (error) {
        props.showNotification('Something went wrong', true)
        props.setLoading(false)
        setError(true)
        setMessage('Could not add Player(s)');
        setOpenSnackbar(true);
      }
      finally {
        setIsLoading(false)
        //Add a refresh method to get the teams again
        props.handleAddPlayer()
      }

    } else {
      console.log('PlayerObject not good')
    }
    //Add Height and Weight to the playerObject
  }

  const handleCloseSnackbar = (event, reason) => {

    if (reason === 'clickaway') {
      // //Add a refresh method to get the teams again
      // props.handleAddPlayer()
      return;
    }
    setOpenSnackbar(false);

    // //Add a refresh method to get the teams again
    // props.handleAddPlayer()
  };

  function handleDelete(idReceived) {

    //handle the display of Modal here, if yes then proceed with the props
    setHandleDeletedClicked(true);
    setTeamToBeDeleted(idReceived)
    handleOpen();

  }

  function handleEdit(idReceived) {
    handleEditOpen()
    const teamName = availableTeams.find((team) => team.id === idReceived)

    if (teamName) {
      setTeamToBeEdited(teamName.name)
      setTeamIdToBeEdited(teamName.id)
      setShowNewTeamModal(true);

      // setError(false)
      // setMessage('Coming soon');
      // setOpenSnackbar(true);

    } else {
      setError(true)
      setMessage('Could not get Team name');
      setOpenSnackbar(true);
    }
  }

  const EditTeamName = async (teamName) => {
    const body = {
      SchoolId: schoolId,
      TeamId: teamIdToBeEdited,
      //   TeamName: newTeamName === '' ? teamToBeEdited?.name : newTeamName
      TeamName: teamName
    }

    setIsLoading(true)
    try {
      const response = await teamRequests.renameTeam(body);
      // props.showNotification(response.status === 200 ? 'Team Edited' : 'Team Could not be edited');
      props.editTeam(response)
      // if (response.status === 200) {
      //   setError(false)
      //   setMessage('Team Edited');
      //   setOpenSnackbar(true);
      // }
      // else {
      //   setError(true)
      //   setMessage('Could not update team name');
      //   setOpenSnackbar(true);
      // }
    }
    catch (error) {
      console.error('Error: ', error)
      setError(true)
      setMessage('Could not update team name');
      setOpenSnackbar(true);
    }
    finally {
      setShowNewTeamModal(false);
      handleEditClose();
      //Fetch Teams so the availableTeams state can be updated
      // await props.fetchTeams()

      setIsLoading(false)
    }
  }

  const handleYesClick = async () => {

    //Testing Snackbar
    // setError(true)
    // setMessage('Testing Scnackbar');
    // setOpenSnackbar(true);


    props.deleteTeam(teamToBeDeleted)
  };

  const ConfirmationModal = ({ open, onClose }) => {

    const teamName = availableTeams.find((team) => team.id === teamToBeDeleted)

    const handleNo = () => {
      onClose();
    };

    const handleYes = () => {

      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Team
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              If you have created a season for this team, you will lose all of your season and team stats.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete {teamName?.name}?
            </Typography>
            {/* Only for spacing */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
              <Button onClick={handleNo} variant='contained'>No</Button>
              <Button onClick={handleYes} variant='contained' color='error'>Yes</Button>
            </div>

          </Box>
        </Modal>
      </div>
    );
  };

  const EditModal = ({ open, onClose }) => {
    const [tempnewTeamName, setTempNewName] = useState(teamToBeEdited);

    const teamName = availableTeams.find((team) => team.id === teamToBeDeleted)

    const handleNo = () => {
      onClose();
    };

    const handleYes = () => {

      handleYesClick();
      onClose();
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>

            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Team
            </Typography>
            <Input
              type="text"
              placeholder="Team Name"
              name="teamName"
              value={tempnewTeamName}
              onChange={(e) => setTempNewName(e.target.value)}
            />
            {/* Only for spacing */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
              <Button onClick={handleNo} variant='contained' color='error'>Cancel</Button>
              <MyButton onClick={() => EditTeamName(tempnewTeamName)}>Edit Team Name</MyButton>

            </div>

          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <div className="team-container">

        {confirmationModalOpen && (
          <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} />
        )}

        {/* Edit team Modal */}
        {editModalOpen && (
          <EditModal open={editModalOpen} onClose={handleEditClose} />
        )}

        <div className='team-header-main'>
          <div className='team-header'>

            <h2 className='team-title'>{props.teamData.name}</h2>
            {
              props.teamData.id !== 999 ?
                <div className='team-header-buttons'>

                  <Button variant="contained" onClick={() => handleEdit(props.teamData.id)}><EditIcon></EditIcon></Button>
                  <Button variant="contained" color="error" className='team-delete-button' onClick={() => handleDelete(props.teamData.id)}>X</Button>


                </div>
                :
                <></>
            }
          </div>
        </div>

        <Droppable key={props.index} droppableId={String(props.teamData.id)}>
          {(provided, snapshot) => (
            <div className='team-list' ref={provided.innerRef} {...provided.droppableProps} isdraggingover={snapshot.isDraggingOver.toString}>
              {
                props.players.length !== 0 && props.players !== undefined ?

                  props?.players?.map((player, index) => {

                    return (
                      <Player key={player.id} playerData={player} index={index} />
                    )
                  }
                  )

                  :
                  <></>
              }

              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <AddPlayer

          isOpen={showAddPlayerModal}
          setReserveReportWindowStatus={setShowAddPlayerModal}
          onAddPlayer={onAddPlayer}
        >
        </AddPlayer>
        {
          // Only available players can have an option to Add Players
          props.teamData.id === 999 ?
            <Button variant='ghost' onClick={addPlayerClick}>Add Player</Button>
            :
            <></>
        }



        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={error ? "error" : "success"}
          >
            {Message}
          </MuiAlert>
        </Snackbar>
      </div>
    </ErrorBoundary>
  );
}