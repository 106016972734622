import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MaterialUIControllerProvider } from './context';
import { store } from './store'
import { Provider } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from './layouts/errorComponent';

// // Registering Syncfusion license key
// // import { registerLicense } from '@syncfusion/ej2-base';

// registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhfcXVURGFcWUdyV0A=');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MaterialUIControllerProvider>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <App />
      </ErrorBoundary>
    </Provider>

  </MaterialUIControllerProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
