import { LteMobiledataOutlined } from "@mui/icons-material";
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";


const initialState = {
    todaysGymSession: [],
    allGymSessions: [],
    activeSelectedGymTeam: "All Teams",
    gymWeeklyFocusPoint: "",
    gymSessions: [],
    //I don't know what to do yet with these arrays:
    finalFutureSessions: [],
    finalTodaySessions: [],
    futureGymSessions: [],
    status: 'idle',
    error: null
}



export const gymSessionSlice = createSlice({
    name: 'gymSession',
    initialState,
    reducers: {
        setFutureGymSessions: (state, action) => {
            state.futureGymSessions = action.payload

        },
        setGymActiveTabTeam: (state, action) => {
            state.activeSelectedGymTeam = action.payload

        },
        setGymStateWeeklyFocusPoint: (state, action) => {
            state.gymWeeklyFocusPoint = action.payload;
        },
        setSplitValues: (state, action) => {
            state.splitValues = action.payload;
        },
        setAllGymSessions: (state, action) => {
            state.allGymSessions = action.payload;
        },
        addFinalFutureSessions: (state, action) => {
            state.finalFutureSessions.push(action.payload);
        },
        addFinalTodaySessions: (state, action) => {
            state.finalTodaySessions = [...state.finalTodaySessions, action.payload]; // Create a new array
        },
        createTodayGymSession: (state, action) => {
            state.todaysGymSession.push(action.payload);
        },

        createSession: (state, action) => {
            state.sessions.push(action.payload);
        },
        //OLD WAY
        editTodaySession: (state, action) => {
            const { sessionType, sessionObject } = action.payload;

            // Find the session to edit based on sessionType
            const sessionToEdit = state.todaysGymSession.find(
                (session) => session.sessionType === sessionType
            );

            if (sessionToEdit) {
                // Update the session with the new data
                sessionToEdit.sessionType = sessionObject.sessionType;
                sessionToEdit.DateTime = sessionObject.time; // Update with your datetime property
                sessionToEdit.splitValues = sessionObject.splitValues;
            }
        },
        editFutureSession: (state, action) => {
            const { sessionType, sessionObject } = action.payload;

            // Find the session to edit based on sessionType
            const sessionToEdit = state.sessions.find(
                (session) => session.sessionType === sessionType
            );

            if (sessionToEdit) {
                // Create a new session object with the updated data
                const updatedSession = {
                    ...sessionToEdit,
                    sessionType: sessionObject.sessionType,
                    DateTime: sessionObject.DateTime,
                    splitValues: sessionObject.splitValues,
                };

                // Replace the old session with the updated one in the array
                state.sessions = state.sessions.map((session) =>
                    session.sessionType === sessionType ? updatedSession : session
                );
            }
        },

        increment: state => {
            state.value += 1
        },
        decrement: state => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        }
    }
})

//Selectors come below:
export const { setFutureGymSessions, setGymActiveTabTeam, setGymStateWeeklyFocusPoint, setSplitValues, setAllGymSessions, addFinalTodaySessions, addFinalFutureSessions, editFutureSession, editTodaySession, createTodayGymSession, createSession, increment, decrement, incrementByAmount } = gymSessionSlice.actions

export default gymSessionSlice.reducer