import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, IconButton, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Loader from '../../layouts/loader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { sessionRequests } from '../../services/api-requests';
import { useState } from 'react';
import { sessionRequests } from '../../services/api-requests';
import { useDispatch } from 'react-redux';
import { setStateWeeklyFocusPoint } from './sessionSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const defaultTheme = createTheme();

export default function WeeklyFocusPoint(props) {
    const { isOpen, setFocusPointWindowOpen, activeTeam, getAllSessions } = props;

    const dispatch = useDispatch()

    const [weeklyFocusPoint, setWeeklyFocusPoint] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [teamId, setTeamId] = useState('');
    const stateTeams = useSelector((state) => state.team.availableTeams)

    useEffect(() => {
        if (activeTeam === "All Teams") {
            setTeamId("All")
        }
        else {
            const teamIWant = stateTeams.filter((team) => team.name === activeTeam)
            const teamIDIwant = teamIWant[0].id
            setTeamId(teamIDIwant)
        }


    }, [activeTeam])

    const submitFocusPoint = async () => {
        //Service Call
        if (!weeklyFocusPoint) {
            setError(true)
            setMessage('Provide a focus point or click cancel');
            setOpenSnackbar(true);
        }
        else {


            setIsLoading(true)

            let listOfFailedTeams = []

            //If teamID is all and user wants to set focus point, loop through all the teams, 
            //and for every team, set the weekly focus point
            if (teamId === "All") {
                // Initialize an array to track failed teams
                const listOfFailedTeams = [];

                stateTeams.forEach(async (team) => {

                    try {
                        const response = await sessionRequests.SetWeeklyFocusPoint(team.id, weeklyFocusPoint);

                        if (response.status === 200) {
                            dispatch(setStateWeeklyFocusPoint(weeklyFocusPoint));
                        } else {
                            // If response status is not 200, add the team to the list of failed teams
                            listOfFailedTeams.push(team);
                        }
                    } catch (error) {
                        // Handle the error
                        console.error('Error: ', error)
                    } finally {
                        // This block will execute whether there is an error or not
                        setFocusPointWindowOpen(false);
                        setIsLoading(false);
                    }
                });

                // Check the length of the listOfFailedTeams array to determine the message
                if (listOfFailedTeams.length === 0) {
                    setError(false)
                    setMessage('All team\'s focus points have been set');
                } else {
                    setError(true)
                    setMessage(`Could not set Focus points for the following teams: ${listOfFailedTeams.map(team => team.name).join(', ')}`);
                }

                // Display the Snackbar
                setOpenSnackbar(true);

            } else {
                try {
                    const response = await sessionRequests.SetWeeklyFocusPoint(teamId, weeklyFocusPoint)
                    // const response = await sessionRequests.SetWeeklyFocusPoint(teamId, weeklyFocusPoint)
                    if (response.status === 200) {
                        setError(false)
                        setMessage('Focus Point added');
                        // dispatch(setStateWeeklyFocusPoint(weeklyFocusPoint))
                        setOpenSnackbar(true);
                    }
                    else {
                        setError(true)
                        setMessage('Could not set Focus Point');
                        setOpenSnackbar(true);
                    }
                } catch (error) {
                    setError(true)
                    setMessage('Could not set Focus Point');
                    setOpenSnackbar(true);
                }
                finally {
                    setFocusPointWindowOpen(false);
                    setIsLoading(false)
                }
            }

        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
        //CallBack to parent
        getAllSessions()
    };

    const handleClose = () => {
        setFocusPointWindowOpen(false);
        setWeeklyFocusPoint('');
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={defaultTheme}>
                {
                    isLoading ?
                        <Loader />
                        :
                        <Dialog open={isOpen} maxWidth="xs" fullWidth={true}>
                            <DialogTitle>
                                <Typography variant="h5" component="div">Set Weekly Focus Point</Typography>
                            </DialogTitle>

                            <DialogContent dividers>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="Focus Point"
                                    label="Focus Point"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={weeklyFocusPoint}
                                    onChange={(e) => setWeeklyFocusPoint(e.target.value)}
                                />


                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={submitFocusPoint}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                }

            </ThemeProvider>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    )
}