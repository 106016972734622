import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { team } from "./team";
import { actions } from "./actions";


const initialState = {
  gameDayStarted: false,
  userLoggedIn: false,
  // startingTeam: team,
  activeTeam: 'A Team',
  opponent: '',
  ageGroup: '',
  // team: '',
  kickoffTime: '',
  tries: [],
  ourScore: 0,
  opponentScore: 0,
  status: 'idle',
  error: null,
  timer: "00:00:00", // Add the timer field to the initial state
  isSecondHalf: false, // Add the isSecondHalf field to track if it's the second half
  stateActions: [],
  kicker: '',
  subbedPlayers: [],
  subs: [],
  cards: [],
  game: [],
  IsTechnical: false,
  GameObject: {},
  impactPlayersArray: [],
  StateManOfTheMatch: {},
  HalfTimeReport: 0,
}

// let response

// // Basic Crud functionality
// export const getTries = createAsyncThunk('site/endpointName', async () => {
//   // const response = await client.get('/api/endpointIamLookingFor', null)
//   return response.data
// })

// export const addTry = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data
// })


// export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
//   return extraData.body 
// })


// export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
//   // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
//   return response.data 
// })



export const gameDaySlice = createSlice({
  name: 'gameDay',
  initialState,
  reducers: {

    updatePlayerGameTime: (state, action) => {
      const playerIWant = state.GameObject.playerStats.find(
        (player) => player.playerId === action.payload.player)

      //This is for players that received a card
      if (action.payload.type === 'card') {
        console.log('Handling player in card: ', playerIWant)

        playerIWant.gameTime = action.payload.gameTime;
      } 

      //This is for players that have been subbed
      if (action.payload.type === 'sub') {
        playerIWant.gameTime = action.payload.gameTime
        state.subbedPlayers.push(action.payload.player)
        //Trying to populate the impactPlayerObject to work with it in the 
        //pdf component
        // state.impactPlayersObject = {
        //   startingTime: action.payload.gameTime,
        //   player: action.payload.subbedWith
        // }

        state.impactPlayersArray.push({
          startingTime: action.payload.gameTime,
          player: action.payload.subbedWith,
        });

      }

      //This is for players that completed the game
      if (action.payload.type === 'completeGame') {
        console.log('Handling player in CompleteGame: ', playerIWant)

        playerIWant.gameTime = action.payload.gameTime
      }

      //This is for players that came off the bench
      if (action.payload.type === 'impact') {
        console.log('Handling player in impact: ', playerIWant)
        playerIWant.gameTime = action.payload.gameTime
      }
    },
    updatePlayerCardDetails: (state, action) => {
      //Find the player in the GameObject.PlayerStats
      const playerIWant = state.GameObject.playerStats.find(
        (player) => player.playerId === action.payload.player)
      //Update the player's stats:
      playerIWant.cardId = parseInt(action.payload.card)

    },
    updateKickerPenalty: (state, action,) => {

      //Find the player in the GameObject.PlayerStats
      const playerIWant = state.GameObject.playerStats.find(
        (player) => player.playerId === action.payload)

      //Update the player's stats:

      playerIWant.pointsMade = playerIWant.pointsMade + 3
    },
    updateKickerConversion: (state, action) => {

      //Find the player in the GameObject.PlayerStats
      const playerIWant = state.GameObject.playerStats.find(
        (player) => player.playerId === action.payload.player)


      //Update the player's stats:
      //Need to figure out when conversion is Made or Missed

      if (action.payload.result === 'over') {
        playerIWant.conversionsMade++
        playerIWant.pointsMade = playerIWant.pointsMade + 2
      }
      else {
        playerIWant.conversionsMissed++
      }
    },
    updateTryScorer: (state, action) => {
      console.log('Player received in reducer: ', action.payload)

      //Find the player in the GameObject.PlayerStats
      const playerIWant = state.GameObject.playerStats.find((player) => player.playerId === action.payload)
      console.log('Player i Want in Reducer: ', current(playerIWant))

      //Update the player's stats:
      playerIWant.triesScored++
      playerIWant.pointsMade = playerIWant.pointsMade + 5
    },
    setStateManOfTheMatch: (state, action) => {
      // Update state.StateManOfTheMatch to the entire object player
      state.StateManOfTheMatch = action.payload;
    },

    updatePlayerStatsMOTM: (state, action) => {
      const playerId = action.payload;
      console.log('MOTM in reducer: ', action.payload)

      // Find the player within GameObject.playerStats
      const playerIWant = state.GameObject.playerStats.find(
        (player) => player.playerId === playerId
      );

      // Update isManOfTheMatch in playerStats
      if (playerIWant) {
        playerIWant.isManOfTheMatch = true;
      }
    },

    setGameObject: (state, action) => {
      state.GameObject = action.payload;
    },
    IncreaseLineoutWon: (state, action) => {
      state.GameObject.extraStats.lineoutsWon++;
    },
    IncreaseLineoutLost: (state, action) => {
      state.GameObject.extraStats.lineoutsLost++;
    },
    IncreaseScrumWon: (state, action) => {
      state.GameObject.extraStats.scrumsWon++;
    },
    IncreaseScrumLost: (state, action) => {
      state.GameObject.extraStats.scrumsLost++;
    },
    IncreaseKnockOnn: (state, action) => {
      state.GameObject.extraStats.knockOns++;
    },
    initializeExtraStats: (state, action) => {
      console.log('Should have been called')

      state.GameObject.extraStats = action.payload;
      console.log('After adding extra: ', state.GameObject.extraStats)

    },
    setGameDayStarted: (state, action) => {
      state.gameDayStarted = action.payload;
    },
    setUserLoggedIn: (state, action) => {
      state.userLoggedIn = action.payload
    },
    setActiveTeam: (state, action) => {
      state.activeTeam = action.payload;
    },
    setOurScore: (state, action) => {
      state.ourScore = action.payload
      state.GameObject.score = action.payload
    },
    setOpponentScore: (state, action) => {
      state.opponentScore = action.payload.action
      state.GameObject.opponentScore = action.payload.action
      if (action.payload.type === 'try') {
        state.GameObject.opponentTries++
      }

    },
    getTeam: (state, action) => {
      return state.startingTeam
    },
    setTimer: (state, action) => {
      state.timer = action.payload; // Update the timer value in the state
      // console.log('timer:', state.timer)
    },
    setIsSecondHalf: (state, action) => {
      state.isSecondHalf = action.payload; // Update the isSecondHalf value in the state
    },
    setOpponent: (state, action) => {
      state.opponent = action.payload;

    },
    pushExtraStatsAction: (state, action) => {
      state.stateActions.push(action.payload)
    },
    emtpyActions: (state, action) => {
      state.stateActions = []
    },
    pushActions: (state, action) => {
      state.stateActions.push(action.payload)
      state.game.push(action.payload)
      console.log(current(state.stateActions))
    },
    setAgeGroup: (state, action) => {
      state.ageGroup = action.payload;
    },
    setTechnicalTable: (state, action) => {
      state.IsTechnical = action.payload
    },
    setHalfTimeReport: (state, action) => {
      console.log('Half time report received in slice: ', action.payload)
      state.HalfTimeReport = action.payload
    },
    setKickoffTime: (state, action) => {
      state.kickoffTime = action.payload;
    },
    setKicker: (state, action) => {
      state.kicker = action.payload
    },
    addSub: (state, action) => {
      state.subs.push(action.payload)
      state.stateActions.push(action.payload)
      state.game.push(action.payload)
      console.log(current(state.subs))
    },
    addCard: (state, action) => {
      state.cards.push(action.payload)
      state.stateActions.push(action.payload)
      state.game.push(action.payload)
      console.log("StateActions", current(state.stateActions))
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    }
  }
})

//Selectors come below:
export const { updatePlayerStatsMOTM, emtpyActions, pushExtraStatsAction, IncreaseLineoutWon, IncreaseLineoutLost,
  IncreaseScrumWon, IncreaseScrumLost,
  IncreaseKnockOnn,
  updatePlayerGameTime, updatePlayerCardDetails,
  updateKickerPenalty, updateKickerConversion, updateTryScorer
  , setStateManOfTheMatch, initializeExtraStats,
  setGameObject, setTechnicalTable, setHalfTimeReport, setGameDayStarted,
  setActiveTeam, setUserLoggedIn, setOpponentScore,
  setOurScore, addCard, addSub, setKicker, pushActions,
  getTeam, setOpponent, setAgeGroup, setKickoffTime,
  setTimer, setIsSecondHalf, decrement, incrementByAmount } = gameDaySlice.actions

export const selectOpponent = state => state.gameDay.opponent;

export const selectKickoffTime = state => state.gameDay.kickoffTime;

export const selectAgeGroup = state => state.gameDay.ageGroup;

export const selectTeam = state => state.gameDay.startingTeam;

export const selectActions = state => state.gameDay.stateActions;

export const selectKicker = state => state.gameDay.kicker;

export const selectStateSubs = state => state.gameDay.subs

export const selectStateCards = state => state.gameDay.cards

export const selectTimer = state => state.gameDay.timer

export default gameDaySlice.reducer