import { configureStore } from '@reduxjs/toolkit'
import teamSlice from './features/team/newTeam/teamSlice'
import gamedaySlice from './features/gameday/gamedaySlice'
import statSlice from './features/stats/statsSlice'
import calendarSlice from './features/calendar/calendarSlice'
import sessionSlice from './features/session/sessionSlice'
import substituionSlice from './features/gameday/substitutionSlice'
import timerSlice from './features/gameday/timerSlice'
import seasonSlice from './features/season/seasonSlice'
import statsSlice from './features/stats/statsSlice'
import userSlice from './features/user/userSlice'
import tmUserSlice from './layouts/trialManager/tmUserSlice'
import { trialManagerSlice } from './layouts/trialManager/trialManagerSlice'
import TrialManager from './layouts/trialManager/trialManager'
import resourcesSlice from './features/resources/resourcesSlice'
import testingSlice from './features/testingSystem/testingSlice'
import gymSessionSlice from './features/gym/gymSessionSlice'

export const store = configureStore({
  reducer: {
    timer: timerSlice,
    team: teamSlice,
    gameday: gamedaySlice,
    substituion: substituionSlice,
    stats: statSlice,
    calendar: calendarSlice,
    session: sessionSlice,
    season: seasonSlice,
    user: userSlice,
    tmUser: tmUserSlice,
    resources: resourcesSlice,
    testingSystem: testingSlice,
    gymSession: gymSessionSlice
  },
})