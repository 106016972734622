import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import Copyright from '../../layouts/copyRight';
import { userRequests } from '../../services/api-requests';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, Paper, TextareaAutosize } from '@mui/material'
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from '../../layouts/loader';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ContactUs() {
    const navigate = useNavigate()
    const [FirstName, setFirstName] = React.useState()
    const [LastName, setLastName] = React.useState()
    const [Email, setEmail] = React.useState()
    const [Message, setMessage] = React.useState()
    const [MessageCategory, setMessageCategory] = React.useState()
    const [categories, setCategories] = React.useState(["Joining", "Account", "School", 'Meeting', 'Careers']);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [error, setError] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [schoolError, setSchoolError] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleCloseSnackbar = (event, reason) => {

        setOpenSnackbar(false);
        if (!error) {
            navigate("/");
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Reset error states
        setEmail(false);
        setFirstNameError(false);
        setLastNameError(false);
        setSchoolError(false);

        // Check if any field is incomplete
        if (!Email || !FirstName || !LastName || !Message || !MessageCategory) {
            setError(true)
            setErrorMessage('Please complete all fields');
            setOpenSnackbar(true);
            return;
        }

        let globalError = false

        try {

            const object = {
                Name: `${FirstName} ${LastName}`,
                Email: Email,
                Category: MessageCategory,
                Message: Message
            }


            setIsLoading(true)
            const response = await userRequests.contactUs(object)

            console.log('Response is: ', response);

            if (response.status === 200) {
                setError(true)
                setErrorMessage('Message has been sent. Thank you for contacting us');
                setOpenSnackbar(true);

            }
            else {
                setError(true)
                setErrorMessage('Could not send message');
                setOpenSnackbar(true);

                globalError = true
            }
        }
        catch (error) {
            // Handle the error here
            setError(true)
            setErrorMessage('Could not send message');
            setOpenSnackbar(true);
            globalError = true
        }
        finally {
            if (!globalError) {
                setIsLoading(false)
                navigate("/");
            }
            setIsLoading(false)
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            {
                isLoading ?
                    <Loader />
                    :
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Grid >
                            <Box

                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >
                                <img draggable="false" loading='lazy' style={{ borderRadius: '10px', marginBottom: '20px', height: '180px' }} src={SquadControlLogo} alt='Squad Control Logo' />

                                <Typography component="h1" variant="h4">
                                    Contact Us
                                </Typography>

                                <Typography component="h6" variant="h6" style={{ marginTop: '20px' }}>
                                    We would love to hear from you.
                                </Typography>

                                <Typography component="h8" variant="h8" style={{ marginTop: '20px', display: 'inline', whiteSpace: 'nowrap' }}>
                                    Please send an email to <Link style={{ display: 'inline' }}>info@redirectsoftware.co.za</Link> with your message.
                                </Typography>

                                <Button
                                    type="button"
                                    onClick={() => navigate('/')}
                                    variant="contained"
                                    sx={{ mt: 4, mb: 2, width: '60%' }}
                                >
                                    Back
                                </Button>

                                {/* <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} lg={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                value={FirstName || ''}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    setFirstNameError(false)
                                                }}
                                                error={firstNameError} // Add error prop
                                                helperText={firstNameError ? 'Please fill in your first name' : ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Last Name"
                                                name="lastName"
                                                autoComplete="family-name"
                                                value={LastName || ''}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    setLastNameError(false)
                                                }}
                                                error={lastNameError} // Add error prop
                                                helperText={lastNameError ? 'Please fill in your last name' : ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                autoComplete="email"
                                                value={Email || ''}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    setEmailError(false)
                                                }}
                                                error={emailError} // Add error prop
                                                helperText={emailError ? 'Please fill in your email' : ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <p>Category of your message</p>
                                            <Select
                                                id="MessageCategory"
                                                name='MessageCategory'
                                                value={MessageCategory || ''}
                                                onChange={(e) => {
                                                    setMessageCategory(e.target.value);

                                                }}
                                                fullWidth
                                                error={schoolError} // Add error prop
                                                helperText={schoolError ? 'Please select the school you are coaching at' : ''}

                                            >
                                                {
                                                    categories.map((cat) => {
                                                        return (
                                                            <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextareaAutosize
                                                style={{
                                                    width: '500px',
                                                    padding: '8px',
                                                    fontFamily: 'Roboto, sans-serif',
                                                    fontSize: '16px',
                                                    borderRadius: '8px',
                                                    border: '1px solid rgba(0, 0, 0, 0.20)',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.00)',
                                                    resize: 'none',
                                                    overflow: 'auto',
                                                }}
                                                minRows={2}
                                                placeholder={`Message`}
                                                value={Message || ''}
                                                onChange={(e) => {
                                                    setMessage(e.target.value);

                                                }}
                                            />
                                        </Grid>

                                    </Grid>

                                    <Button
                                        type="submit"

                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, width: '60%' }}
                                    >
                                        Contact Us
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => navigate('/')}
                                        variant="outlined"
                                        sx={{ mt: 0, mb: 2, width: '60%' }}
                                    >
                                        Back
                                    </Button>

                                    <Snackbar
                                        open={openSnackbar}
                                        autoHideDuration={4000}
                                        onClose={handleCloseSnackbar}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    >
                                        <MuiAlert
                                            elevation={6}
                                            variant="filled"
                                            onClose={handleCloseSnackbar}
                                            severity={error ? "error" : 'success'}
                                        >
                                            {errorMessage}
                                        </MuiAlert>
                                    </Snackbar>

                                </Box> */}
                            </Box>
                        </Grid>
                        <br />
                        <br />
                        <Copyright sx={{ mt: 5 }} />
                    </Container>
            }

        </ThemeProvider>
    );
}