import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Drag and Drop
import { DragDropContext } from 'react-beautiful-dnd';
import { TeamList } from './teamList'

//Components
import { MyButton } from './newTeam/newButton';
import { Button, } from '@mui/material';
import Modal from './newTeam/newModal';
import Input from './newTeam/newInput';

//Misc
import { playerRequests, teamRequests } from '../../services/api-requests';
import './teamStyles.css';
import PrintTeamSelection from './printSelection';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../layouts/loader.js';
import { setAvailableTeams } from './newTeam/teamSlice.js';

export const ReservePropagation = React.createContext();

function TeamLists(props) {
  const dispatch = useDispatch()
  //Coach Data
  const ageGroup = useSelector((state) => parseInt(state.user.profile.ageGroup))
  const schoolId = useSelector((state) => parseInt(state.user.profile.school))

  //Data Handling
  const [data, setData] = useState(props.initialList);
  const [reserves, setReserves] = useState([]);
  const [updated, setUpdated] = useState(false)
  const [submittedTeams, setSubmittedTeams] = useState([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [Message, setMessage] = React.useState('');

  //Create Team Modal
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [newTeamName, setTeamName] = useState('');

  //======================================= Hooks ==============================================


  useEffect(() => {
    //when data updates create the number of reserve arrays to match the number of teams
    const reserveArrays = []
    data.teams.forEach((team) => {
      if (team.id !== 999) {
        const reserveObject = {
          team: team.name,
          players: [],
          id: team.id
        }
        reserveArrays.push(reserveObject)
      }
    })
    setReserves(reserveArrays)
  }, [data]);


  // ======================================= Functions ==========================================

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      setOpenSnackbar(false);
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmitTeams = async () => {
    setUpdated(true)
    //remove available players team from teams list
    const teams = data?.teams?.filter(team => team.id !== 999)

    var submitTeams = []
    teams.forEach((team) => {
      submitTeams.push({
        schoolId: schoolId,
        teamId: team.id,
        teamName: team.name,
        players: team.players,
        reserves: reserves.find(reserve => reserve.id === team.id).players
      });
    });
    setSubmittedTeams(submitTeams)

    try {
      setIsLoading(true)
      await teamRequests.updateTeams(submitTeams).then((response) => {

        props.setLoading(true)
        props.showNotification(response.status === 200 ? 'Teams Submitted' : 'Teams Could not be submitted');
        props.setLoading(false)
      });
    }
    catch (error) {
      console.error('Error in updateTeams: ', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleAddPlayer = () => {
    props.PlayerAdded();
  }

  // const handleReserveSubmit = async (playerId, teamId) => {
  //   //find all player references in reserve arrays

  //   console.log('Playerid: ', playerId)
  //   console.log('teamId: ', teamId)

  //   const reqBody = {
  //     playerId: playerId,
  //     teamId: teamId,
  //   }

  //   if (playerId && teamId) {
  //     try {
  //       setIsLoading(true)
  //       const response = await playerRequests.setReserveTeam(reqBody)

  //       if (response.status === 200) {
  //         setUpdated(true)
  //         alert('Response is good')
  //         console.log('Player set to reserve')
  //         console.log('response.status: ', response.data)

  //         //Normal Functionality
  //         reserves.forEach((reserve) => {
  //           const index = reserve.players.indexOf(playerId)
  //           if (index > -1) {
  //             //remove player from reserve array
  //             reserve.players.splice(index, 1)
  //           }
  //         });



  //         // Find reserve array for team and add player
  //         const teamReserve = reserves.find(reserve => reserve.id === teamId);
  //         if (teamReserve) {
  //           teamReserve.players.push(playerId);
  //         } else {
  //           console.error('Team reserve not found for teamId:', teamId);
  //         }

  //         //update reserves state
  //         setReserves(reserves)

  //         // // Find player reference in players array and set isReserve accordingly
  //         // const player = data.players.find((player) => player.id === playerId);
  //         // if (reqBody.teamId === -1) {
  //         //   player.isReserve = false; // Player removed from reserve
  //         // } else {
  //         //   player.isReserve = true; // Player added to reserve
  //         // }

  //         // Update players array: Set `isReserve` flag for the player
  //         const updatedPlayers = data.players.map(player =>
  //           player.id === playerId ? { ...player, isReserve: teamId !== -1 } : player
  //         );

  //         console.log('Updated players are: ', updatedPlayers)

  //         // Update the data state with new reserves and players
  //         const newState = {
  //           ...data,
  //           players: updatedPlayers,
  //           teams: data.teams,  // Teams remain unchanged
  //         };
  //         console.log('new State: ', newState)

  //         setData(newState); // Update state with the new data structure


  //         //Notification
  //         setOpenSnackbar(true);
  //         setMessage(reqBody.teamId === -1 ? 'Reserve Removed' : 'Reserve Added');
  //         setError(false)
  //       }
  //       else {
  //         setOpenSnackbar(true);
  //         setMessage(reqBody.teamId === -1 ? 'Could not Remove Reserve else' : 'Could not Add Reserve');
  //         setError(true)
  //       }
  //     } catch (error) {
  //       console.error(error)
  //       setOpenSnackbar(true);
  //       setMessage(reqBody.teamId === -1 ? 'Could not Remove Reserve catch' : 'Could not Add Reserve');
  //       setError(true)
  //     }
  //     finally {
  //       setIsLoading(false)
  //     }
  //   }
  //   else {
  //     console.log('Team ID or Player ID was undefined. Could not add player as reserve')
  //     setOpenSnackbar(true);
  //     setMessage('Could not add reserve');
  //     setError(true)
  //   }
  // }

  /////NEW ONE


  const handleReserveSubmit = async (playerId, teamId, reservePosition) => {
    // console.log('Reserve position in parent: ', reservePosition);

    if (!playerId || teamId === undefined) {
      console.error('Team ID, Player ID, or reservePosition was undefined. Could not add player as reserve');
      setOpenSnackbar(true);
      setMessage('Could not add reserve');
      setError(true);
      return;
    }

    const reqBody = { playerId, teamId };

    try {
      setIsLoading(true);

      const response = await playerRequests.setReserveTeam(reqBody);

      if (response.status === 200) {
        setUpdated(true);
        //  console.log('Player set to reserve or removed from reserve');

        // Step 1: Find the full player object
        const playerToUpdate = data.players.find(player => player.id === playerId);
        if (!playerToUpdate) {
          console.error('Player not found in the players array');
          return;
        }

        // Step 2: Update the teams with the full player object
        const updatedTeams = data.teams.map(team => {
          if (team.reservePlayers) {
            // If removing player from the reserve (teamId === -1)
            if (team.reservePlayers.some(p => p.id === playerId)) {
              return {
                ...team,
                reservePlayers: team.reservePlayers.filter(p => p.id !== playerId) // Remove player from previous reserve
              };
            }

            // If adding the player to a team's reserve (teamId matches)
            if (team.id === teamId) {
              const updatedReservePlayers = [...team.reservePlayers];

              // Insert player at correct reserve position
              if (reservePosition === 1) {
                updatedReservePlayers.splice(0, 0, playerToUpdate); // Insert at index 0
              } else if (reservePosition === 2) {
                updatedReservePlayers.splice(1, 0, playerToUpdate); // Insert at index 1
              } else if (reservePosition === 3) {
                updatedReservePlayers.splice(2, 0, playerToUpdate); // Insert at index 2
              } else if (reservePosition === 4 || reservePosition === 5) {
                updatedReservePlayers.splice(3, 0, playerToUpdate); // Insert at index 3 or 4
              } else if (reservePosition >= 6 && reservePosition <= 8) {
                updatedReservePlayers.splice(4, 0, playerToUpdate); // Insert at index 4 or 5
              } else {
                updatedReservePlayers.push(playerToUpdate); // Insert at the end
              }

              return {
                ...team,
                reservePlayers: updatedReservePlayers, // Update the team's reserve players
              };
            }

            //  console.log('Player to update: ', playerToUpdate);
          }
          return team;
        });

        // Step 3: Update the players list to reflect reserve status
        const updatedPlayers = data.players.map(player =>
          player.id === playerId ? { ...player, isReserve: teamId !== -1 } : player
        );

        // Step 4: Update the state with the new team and player data
        const newState = {
          ...data,
          players: updatedPlayers,
          teams: updatedTeams,
        };

        //   console.log('newState: ', newState);

        // Step 5: Set the updated state so that it gets passed down correctly to PrintTeamSelection
        setData(newState);

        // Notify user
        setOpenSnackbar(true);
        setMessage(teamId === -1 ? 'Reserve Removed' : 'Reserve Added');
        setError(false);
      } else {
        setOpenSnackbar(true);
        setMessage(teamId === -1 ? 'Could not Remove Reserve' : 'Could not Add Reserve');
        setError(true);
      }
    } catch (error) {
      console.error(error);
      setOpenSnackbar(true);
      setMessage(teamId === -1 ? 'Could not Remove Reserve' : 'Could not Add Reserve');
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };


  function openMySnackbar() {
    setOpenSnackbar(true);
    setMessage('Coming soon!');
    setError(false);
  }



  const fetchTeams = async () => {
    setIsLoading(true);

    try {
      const response = await teamRequests.getTeams(schoolId, ageGroup);

      if (response.status === 200) {
        // console.log('Response from get teams after creating teams: ', response.data
        // )
        dispatch(setAvailableTeams(response.data));
      } else {
        console.error('Could not get teams')
      }
    } catch (error) {
      console.error('Error fetching Teams:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const CreateTeamContainer = async () => {
    setIsLoading(true)
    try {
      const response = await teamRequests.addTeam(schoolId, ageGroup, newTeamName);
      props.showNotification(response.status === 200 ? 'Team Added' : 'Team Could not be added');
      addTeamToData(response.data);
    }
    catch (error) {
      console.error('Error: ', error)
    }
    finally {
      setShowNewTeamModal(false);
      //Fetch Teams so the availableTeams state can be updated
      await fetchTeams()

      setIsLoading(false)
    }
  }

  // ===================================== Drag and Drop ========================================

  //Main Drag and Drop Function works perfect and all data handling is done other places so don't touch
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Moving from one list to another
    const startTeam = data.teams.find(team => team.id === parseInt(source.droppableId, 10));
    const finishTeam = data.teams.find(team => team.id === parseInt(destination.droppableId, 10));
    const startPlayerIndex = startTeam.players.indexOf(parseInt(draggableId, 10));

    // Moving within the same team
    if (startTeam === finishTeam) {
      startTeam.players.splice(startPlayerIndex, 1);
      startTeam.players.splice(destination.index, 0, parseInt(draggableId, 10));

      const newState = {
        ...data,
        teams: data.teams.map(team =>
          team.id === startTeam.id ? startTeam : team
        ),
      };

      setData(newState);
      return;
    }

    // Moving from one team to another
    startTeam.players.splice(startPlayerIndex, 1);
    finishTeam.players.splice(destination.index, 0, parseInt(draggableId, 10));

    // Update state
    const newState = {
      ...data,
      teams: data.teams.map(team =>
        team.id === startTeam.id ? startTeam : team.id === finishTeam.id ? finishTeam : team
      ),
    };

    setData(newState);
  };

  // ==================================== Helper Functions ========================================

  const addTeamToData = (team) => {
    setIsLoading(true)
    const newState = {
      ...data,
      teams: [...data.teams, team]
    }

    setData(newState);
    setIsLoading(false)
  }

  function childNotification(MessageValue, errorValue) {
    console.log('In the parent: ', MessageValue, ' ', errorValue)
    props.showNotification(MessageValue, errorValue)
  }


  function childLoading(loadingValue) {
    console.log('In the parent: ', loadingValue, ' ',)
    props.setLoading(loadingValue)
  }


  const deleteTeamFromData = (teamId) => {
    //move all players from deleted team to available players
    const deletedTeam = data.teams.find(team => team.id === teamId)
    const availablePlayers = data.teams.find(team => team.id === 999)
    availablePlayers.players = [...availablePlayers.players, ...deletedTeam.players]

    //remove deleted team from teams list
    const newState = {
      ...data,
      teams: data.teams.filter(team => team.id !== teamId)
    }

    setData(newState);

    //remove deleted team from reserves list
    const newReserves = reserves.filter(reserve => reserve.id !== teamId)
    setReserves(newReserves)

    try {
      teamRequests.deleteTeam(teamId).then((response) => {
        props.showNotification(response.status === 200 ? 'Team Deleted' : 'Team Could not be deleted');
      });
    }
    catch (error) {
      console.error('Error in deleteTeam: ', error)
    }
  };

  function teamEdited(response) {
    props.showNotification(response.status === 200 ? 'Team Edited' : 'Team Could not be edited');
  }


  //=================================== Render ===================================

  return (

    <div className="mx-auto max-w-7xl py-10" style={{ marginTop: '-65px' }}>

      {
        isLoading ?
          <Loader />
          :
          <></>
      }

      <ReservePropagation.Provider value={{
        onReserveSubmit: handleReserveSubmit,
      }}>
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', margin: "0px 15px 5px" }}>
          <MyButton onClick={() => setShowNewTeamModal(true)}>
            Add Team
          </MyButton>
        </div> */}

        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 15px 0px' }}>
          {/* <Button variant='contained'>Print Teams</Button> */}
          {/* <PrintTeamSelection data={updated ? data : { players: props.initialList.players, teams: props.initialList.teams }} /> */}
          <PrintTeamSelection data={updated ? data : { players: props.initialList.players, teams: props.initialList.teams }} openMySnackbar={openMySnackbar} />

          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px 15px 0px' }}>
            <Button variant='contained' onClick={handleSubmitTeams} style={{ marginRight: '10px' }}>Submit Teams</Button>
            <MyButton onClick={() => setShowNewTeamModal(true)}>Add Team</MyButton>
          </div>

        </div>

        <Modal
          showModal={showNewTeamModal}
          setShowModal={setShowNewTeamModal}
        >
          <div className="flex flex-col w-full items-start gap-y-4">
            <h1 className="text-gray-800 text-3xl font-bold">Add Team</h1>
            <Input
              type="text"
              placeholder="Team Name"
              name="teamName"
              value={newTeamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <MyButton onClick={() => { CreateTeamContainer() }}>Add Team</MyButton>
          </div>
        </Modal>

        {/* <AddPlayer
          isOpen={isAddPlayerPopupOpen}
          setReserveReportWindowStatus={setIsAddPlayerPopupOpen}
          onAddPlayer={onAddPlayer}
        >
        </AddPlayer> */}

        {data?.teams?.length === 0 ?
          <h1 className="text-center text-2xl font-bold">No Teams</h1>
          :
          <DragDropContext onDragEnd={onDragEnd} className="team-grid">
            <div className='team-view'>
              <div className="team-grid">
                {
                  data?.teams?.map((team, index) => {
                    //Teams
                    if (team.id === 999) return null;

                    const players = team.players.map(playerRef => data.players.find(player => player.id == playerRef))
                    return <TeamList index={index} key={team.id} teamData={team} players={players} deleteTeam={deleteTeamFromData} editTeam={teamEdited} />
                  })
                }
              </div>
              <div className="available-players">
                {
                  data?.teams?.map((team, index) => {
                    //Available Players
                    if (team.id !== 999) return null;

                    const players = team.players.map(playerRef => data.players.find(player => player.id == playerRef))
                    return <TeamList showNotification={childNotification}
                      setLoading={childLoading} handleAddPlayer={handleAddPlayer}
                      index={index} key={team.id} teamData={team} players={players}
                    />
                  })
                }
              </div>
            </div>
          </DragDropContext>
        }
      </ReservePropagation.Provider>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={error ? "error" : "success"}
        >
          {Message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default TeamLists;
