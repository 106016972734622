import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { gamedayStats, seasonRequests, seasonStats, sessionStats } from '../../services/api-requests';
import Loader from '../../layouts/loader';
import { useState } from 'react';
import StatGrid from '../stats/statGrid';
import { useEffect } from 'react';
import Title from '../dashboard/Content/title';
import { useSelector } from 'react-redux';
import PrintPastSeasons from './printpastSeasons';



function HandlePastSeasons(props) {

    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [seasonId, setSeasonId] = useState(props.seasonId);
    const [teamId, setTeamId] = useState(props.teamId);
    const [noTeamSelected, setNoTeamSelected] = useState(false);

    const [sessionsDone, setSessionsDone] = useState([]);
    const [gameWinPercent, setGameWinPercent] = useState([]);
    const [minutesTrained, setMinutesTrained] = useState([])
    const [gamesPlayed, setGamesPlayed] = useState([]);
    const availableTeams = useSelector((state) => state.team.availableTeams)
    const activeSeason = useSelector((state) => state.season.activeSeason);

    //This variable gets used to identify which team gets used because parent state is lagging
    let newTeamId


    // useEffect(() => {
    //     // alert('Changed')
    // }, [activeSeason])

    //ORIGINAL - STATE ALWAYS ONE CLICK LATE
    // useEffect(() => {
    //     console.log('Active Season is: ', activeSeason)
    //     setIsLoading(true)

    //     console.log('Props here: ', props)
    //     const team = availableTeams.find((team) => team.name === activeSeason);
    //     console.log('Team found: ', team)
    //     newTeamId = parseInt(team.id)
    //     setTeamId(team ? team.id : 'No Season ID')


    //     getWinPercentage()
    //     getMatchesPlayed();
    //     getMinutesTrained();
    //     getSessionsDone();

    //     setIsLoading(false)


    // }, [activeSeason])

    //NEW ONE - WORKS WELL BUT NOT ALL THE TIME
    // useEffect(() => {
    //     //  if (!activeSeason) return; // Guard clause in case activeSeason is not ready
    //     console.log('ActiveSeason is: ', activeSeason)
    //     const team = availableTeams.find((team) => team.name === activeSeason);
    //     if (team) {
    //         setTeamId(team.id);  // Safely update the state with the teamId
    //         // No need for a separate newTeamId variable
    //     } else {
    //         alert('No team found')
    //         setTeamId(null);  // Handle case when no team is found
    //     }
    // }, [activeSeason, availableTeams]); // React will re-run this effect when either activeSeason or availableTeams changes

    // useEffect(() => {
    //     if (teamId) {
    //         setIsLoading(true);
    //         getWinPercentage(teamId); // Passing teamId from state
    //         getMatchesPlayed(teamId);
    //         getMinutesTrained(teamId);
    //         getSessionsDone(teamId);
    //     }
    // }, [teamId]); // Runs this effect when teamId is updated


    //works better but also renders lagged state sometimes
    // useEffect(() => {
    //     if (!activeSeason || availableTeams.length === 0) return; // Guard clause to prevent early execution

    //     const team = availableTeams.find((team) => team.name === activeSeason);

    //     if (team) {
    //         setTeamId(team.id); // Update state properly
    //     } else {
    //         setTeamId(null);  // Handle when no team is found
    //     }
    // }, [activeSeason, availableTeams]);  // Run effect when activeSeason or availableTeams changes


    // useEffect(() => {
    //     // Ensure teamId exists before making API calls
    //     if (!teamId) return;

    //     const fetchTeamStats = async () => {
    //         setIsLoading(true);

    //         try {
    //             // Await all API calls in parallel using Promise.all
    //             const [winPercentResponse, matchesPlayedResponse, minutesTrainedResponse, sessionsDoneResponse] = await Promise.all([
    //                 gamedayStats.GetWinPercentage(teamId, true),
    //                 seasonStats.GetSeasonStatsSummary(teamId, true),
    //                 sessionStats.GetMinutesTrained(teamId),
    //                 sessionStats.GetNumberOfSessions(teamId)
    //             ]);

    //             if (winPercentResponse.status === 200) {
    //                 setGameWinPercent(winPercentResponse.data);
    //             }

    //             if (matchesPlayedResponse.status === 200) {
    //                 setGamesPlayed(matchesPlayedResponse?.data?.gamesPlayed);
    //             }

    //             if (minutesTrainedResponse.status === 200) {
    //                 setMinutesTrained(minutesTrainedResponse.data);
    //             }

    //             if (sessionsDoneResponse.status === 200) {
    //                 setSessionsDone(sessionsDoneResponse.data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching stats:', error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     fetchTeamStats(); // Call the async function
    // }, [teamId]); // Re-run when teamId updates

    useEffect(() => {
        if (!activeSeason || availableTeams.length === 0) return; // Ensure data is available before proceeding

        const team = availableTeams.find((team) => team.name === activeSeason);
        if (team) {
            setTeamId(team.id); // Properly update the teamId when the activeSeason changes
        } else {
            setTeamId(null); // Handle cases where no team is found
        }
    }, [activeSeason, availableTeams]);

    useEffect(() => {
        // Only fetch stats if a valid teamId is set
        if (!teamId) return;

        const fetchTeamStats = async () => {
            setIsLoading(true);

            try {
                // Fetch all data in parallel using Promise.all
                const [
                    winPercentResponse,
                    matchesPlayedResponse,
                    minutesTrainedResponse,
                    sessionsDoneResponse
                ] = await Promise.all([
                    gamedayStats.GetWinPercentage(teamId, true),
                    seasonStats.GetSeasonStatsSummary(teamId, true),
                    sessionStats.GetMinutesTrained(teamId),
                    sessionStats.GetNumberOfSessions(teamId)
                ]);

                // Combine state updates to prevent intermediate renders with partial data
                setGameWinPercent(() => winPercentResponse.status === 200 ? winPercentResponse.data : 0);
                setGamesPlayed(() => matchesPlayedResponse.status === 200 ? matchesPlayedResponse.data?.gamesPlayed : 0);
                setMinutesTrained(() => minutesTrainedResponse.status === 200 ? minutesTrainedResponse.data : 0);
                setSessionsDone(() => sessionsDoneResponse.status === 200 ? sessionsDoneResponse.data : 0);

            } catch (error) {
                console.error('Error fetching team stats:', error);
            } finally {
                setIsLoading(false); // Ensure loading state is toggled off
            }
        };

        fetchTeamStats();
    }, [teamId]);  // Use teamId as the dependency




    function handleCloseConfirmationSnackbar() {
        setOpenConfirmationSnackbar(false)
        props.refreshSeason()
    }


    async function handlePrintSeason() {
        setIsLoading(true)

        try {

            ////////NEED TO PRINT THE SEASON STATS FOR THIS TEAM and SEASON

            const response = await seasonRequests.PrintSeason(seasonId)

            if (response.status === 200) {
                setConfirmationMessage("Season has been printed")
                setError(false)
                setOpenConfirmationSnackbar(true)
            }
            else {
                setConfirmationMessage("Something went wrong")
                console.error('Something went wrong ', response.data)
                setError(true)
                setOpenConfirmationSnackbar(true)
            }
        }
        catch (error) {
            console.error('Something went wrong ', error)
        }
        finally {
            setIsLoading(false)
        }
    }


    const getWinPercentage = async () => {

        setIsLoading(true);
        try {
            const response = await gamedayStats.GetWinPercentage(teamId, true);
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Win Percentage received: ', response.data)

            if (response.status === 200) {

                setGameWinPercent(response.data)

            }
            else {
                console.log('In the else');

            }

        } catch (error) {
            console.error('Error fetching win percentage:', error);
        } finally {
            setIsLoading(false);

        }
    };

    const getSessionsDone = async () => {

        setIsLoading(true);
        try {
            const response = await sessionStats.GetNumberOfSessions(teamId);
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Sessions Done received: ', response.data)

            if (response.status === 200) {

                setSessionsDone(response.data)

            }
            else {
                console.log('In the else');

            }

        } catch (error) {
            console.error('Error fetching nr of sessions:', error);
        } finally {
            setIsLoading(false);

        }
    };

    const getMinutesTrained = async () => {

        setIsLoading(true);
        try {
            const response = await sessionStats.GetMinutesTrained(teamId);
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Minutes trained received: ', response.data)

            if (response.status === 200) {

                setMinutesTrained(response.data)

            }
            else {
                console.log('In the else');

            }

        } catch (error) {
            console.error('Error fetching minutes trained:', error);
        } finally {
            setIsLoading(false);

        }
    };

    const getMatchesPlayed = async () => {

        setIsLoading(true);
        try {
            const response = await seasonStats.GetSeasonStatsSummary(teamId, true);
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Games played received: ', response.data)

            if (response.status === 200) {

                setGamesPlayed(response?.data?.gamesPlayed)

            }
            else {
                console.log('In the else');

            }

        } catch (error) {
            console.error('Error fetching games played:', error);
        } finally {
            setIsLoading(false);

        }
    };

    function onRefresh() {
        props.refreshSeason();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {
                isLoading ?
                    <Loader />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column', // Stack elements vertically on small screens
                                marginTop: '-30px'
                            }}
                        >
                            <Title style={{ textAlign: 'center', marginBottom: '10px', marginTop: '-20px' }}>
                                {props.season?.type ? props.season?.type : 'Season'}
                            </Title>

                            <Title style={{ textAlign: 'center', marginBottom: '10px' }}>Your season has ended. Take a look back before creating a new one</Title>
                        </div>


                        <Typography variant='h5'></Typography>
                        <br />

                        <Grid container spacing={3} style={{ display: "flex", flexDirection: "row", marginBottom: '20px' }}>
                            <StatGrid title="Game Win %"
                                metric={noTeamSelected ? "No Team Selected" : gameWinPercent ? gameWinPercent : 0}
                                size={6}
                                info1="Games won"
                            />

                            <StatGrid
                                title="Games Played"
                                metric={noTeamSelected ? "No Team Selected" : (gamesPlayed ? gamesPlayed : 0)}
                                size={6}
                                info1="games played"
                            />
                        </Grid>
                        <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>

                            <StatGrid title="Sessions done"
                                metric={noTeamSelected ? "No Team Selected" : (sessionsDone ? sessionsDone : 0)}
                                size={6}
                                info1="Sessions completed"
                            />

                            <StatGrid title="Minutes Trained"
                                metric={noTeamSelected ? "No Team Selected" : (minutesTrained ? minutesTrained : 0)}
                                size={6}
                                info1="minutes in training"
                            />

                        </Grid>
                        <br />
                        {/* <Button onClick={() => handlePrintSeason()} variant='contained'>Print Season Results</Button> */}
                        <PrintPastSeasons
                            gamesWon={gameWinPercent}
                            gamesPlayed={gamesPlayed}
                            sessionsDone={sessionsDone}
                            minutesTrained={minutesTrained}
                            selectedTeamId={teamId}
                            refresh={onRefresh}
                        />
                    </div>
            }

            <Snackbar
                open={openConfirmationSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {confirmationMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default HandlePastSeasons