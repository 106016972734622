import { useState } from 'react';
import React from "react";
import { Button, Card, CardContent, CardActions, Typography, Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Resource() {
  const navigate = useNavigate();

  function navigateToFiles() {
    navigate('/Resource/Files');
  };

  function navigateToTemplates() {
    navigate('/Resource/Templates');
  };

  function navigateToUserGuides() {
    navigate('/Resource/UserGuides');
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Playing Guides
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Guides for game play and important documents.
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button variant="contained" onClick={navigateToFiles}>
                Go to playing guides
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Templates
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Templates in Excel format.
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button variant="contained" onClick={navigateToTemplates}>
                Go to templates
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                User Guides
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Guides for how to use the system.
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button variant="contained" onClick={navigateToUserGuides}>
                Go to user guides
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>

  );
}

export default Resource;
