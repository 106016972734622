import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";


const initialState = {
    season: [],
    activeSeason: 'In Season',
    error: null,
    //Might need to nest all of this into season array above
    // seasonType: '',
    // seasonStartDate: '',
    // seasonEndDate: '',
    // totalGames: '',
    openSetupGames: false,
    games: []
    // [{
    //     id: 0,
    //     opponent: '',
    //     date: '',
    //     venue: '',
    //     type: '',
    // }]
    ,
    seasonStarted: false,
    seasonObj: {},
    backendSeason: {},
    allSeasons: [],
    changed: false,
    nrOfGames: 0,
    teamsSeasons: [],
    somethingChanged: false
}

let response

// // Basic Crud functionality
// export const fetchSites = createAsyncThunk('site/endpointName', async () => {
//     // const response = await client.get('/api/endpointIamLookingFor', null)
//     const response = {};
//     return response.data
// })

// export const addSite = createAsyncThunk('site/endpointName', async (extraData) => {
//     // const response = await client.post('/api/endpointIamLookingFor', extraData.body, null)
//     const response = {};
//     return response.data
// })


// export const updateSite = createAsyncThunk('site/endpointName', async (extraData) => {
//     // const response = await client.put('/api/endpointIamLookingFor/', extraData.body, null)
//     const response = {};
//     return extraData.body
// })


// export const deleteSite = createAsyncThunk('site/endpointName', async (extraData) => {
//     // const response = await client.delete('/api/endpointIamLookingFor', extraData.body, null)
//     const response = {};
//     return response.data
// })


export const seasonSlice = createSlice({
    name: 'season',
    initialState,
    reducers: {
        setSomethingChanged: (state, action) => {
            state.somethingChanged = action.payload
        },
        setTeamsSeasons: (state, action) => {
            state.teamsSeasons.push(action.payload)
        },
        setChanged: (state, action) => {
            state.changed = action.payload
        },
        setStateNrOfGames: (state, action) => {
            state.nrOfGames = action.payload
        },
        setAllSeasons: (state, action) => {
            const { teamId, seasons } = action.payload;
            state.allSeasons[teamId] = seasons;
        },

        setActiveSeason: (state, action) => {
            state.activeSeason = action.payload;
        },
        setStateWeeklyFocusPoint: (state, action) => {
            state.weeklyFocusPoint = action.payload;
        },
        setStateSeasonStarted: (state, action) => {

            // console.log('Action : ', action)
            // console.log('Action payload with this type of thing: ', action.payload)
            state.seasonStarted = action.payload
            // console.log('Season Started inslice: ', state.seasonStarted)
            // console.log('Season Started inslice: ', current(state.SeasonStarted))
        },
        setStateOpenSetupGames: (state, action) => {
            state.openSetupGames = action.payload;
        },
        // setTotalGames: (state, action) => {
        //     state.season.totalGames = action.payload
        // },
        setSeasonGames: (state, action) => {
            state.games.push(...action.payload)
            state.season[0].games.push(...action.payload)
        },
        UpdateSeasonGames: (state, action) => {
            state.backendSeason.games = action.payload;
        },
        addSeason: (state, action) => {
            state.season.push(action.payload)
            // console.log('Season after push: ', state.season)
        },
        setSeasonObj: (state, action) => {
            state.seasonObj = action.payload;
        },
        setBackendSeason: (state, action) => {
            // console.log('payload: ', action.payload)
            state.backendSeason = action.payload;
        },
        increment: state => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value += 1
        },
        decrement: state => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        }
    }
})

//Selectors come below:
export const { setSomethingChanged, setTeamsSeasons, setStateNrOfGames, setChanged, setAllSeasons,
    addSeason, setSeasonGames, setTotalGames,
    setStateOpenSetupGames, setStateSeasonStarted,
    setActiveSeason, setSeasonObj, increment,
    decrement, incrementByAmount, setBackendSeason, UpdateSeasonGames,
} = seasonSlice.actions

export default seasonSlice.reducer