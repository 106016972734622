import React, { useState } from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux'
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { setAdmin, setProfile } from './features/user/userSlice.js';
import { useDispatch } from 'react-redux';
import Stats from './features/stats/stats.js';
// import Aside from './components/Aside.js';
import Session from './features/session/session.js';
import Calendar from './features/calendar/calendar.js';
import Settings from './features/settings/settings.js'
// // import Dashboard from './features/dashboard/dashboard.js';
import Paperbase from './features/dashboard/Paperbase.js';
import SignInSide from './features/signIn/signIn.js';
import SignUp from './features/signUp/signUp.js';
// // import Dashboard from './features/dashboard/index.js';
import Dashboard from './features/dashboard/dashboard.js';

// import NotFoundPage from './layouts/notFoundPage'
import GameDay from './features/gameday/gameday.js';
// import AddTodaySession from './features/session/addTodaySession.js';
// import AddFutureSession from './features/session/addFutureSession.js';
// import EditTodaySesions from './features/session/editTodaySession.js';
// import EditFutureSessions from './features/session/editFutureSessions.js';
import Season from './features/season/season.js';
// import TrialManager from './layouts/trialManager/trialManager.js';
// import TrialPaperbase from './layouts/trialManager/trialPaperBase.js';

// import TmSignIn from './layouts/trialManager/tmSignInPage.js';
// import TmSetupProfile from './layouts/trialManager/tmSetupProfile.js';
// import TmUploadTeams from './layouts/trialManager/tmUploadTeams.js';
// import TmGameTime from './layouts/trialManager/GameTime/tmGameTime.js';
// import TmReport from './layouts/trialManager/tmReport.js';
// import TmUploadTeamsAuto from './layouts/trialManager/tmUploadTeamsAuto.js';
// import TmUploadTeamsManual from './layouts/trialManager/tmUploadTeamsManual.js';
// import TmGameTimeAuto from './layouts/trialManager/GameTime/tmGameTimeAuto.js';
// import TmGameTimeManual from './layouts/trialManager/GameTime/tmGameTimeManual.js';
// import RegisterSchool from './features/registerSchool/registerSchool.js';
import AdminDashboard from './layouts/adminDetails/adminDashboard.js';
// import Managers from './layouts/adminDetails/managers.js';
// import Schools from './layouts/adminDetails/schools.js';
// import Coaches from './layouts/adminDetails/coaches.js';
// import Players from './layouts/adminDetails/players.js';
// import ManageSCTeam from './layouts/adminDetails/manageSCTeam.js';
// import DeveloperSettings from './layouts/adminDetails/developerSettings.js';
// import EditCoach from './features/settings/editCoach.js';
// import EditManager from './layouts/adminDetails/editMangers.js';
import { ManageCoaches } from './features/settings/manageCoaches.js';
// import EditPlayer from './layouts/adminDetails/editPlayer.js';

import Team from './features/team/team.js';
// import UpdateSeason from './features/season/updateSeason.js';
// // import { setUserLoggedIn } from './features/gameday/gamedaySlice.js';
import Resource from './features/resources/resources.js';
// import FileView from './features/resources/files.js';
// import TemplateView from './features/resources/templates.js';
// import { ApproveCoaches } from './layouts/adminDetails/approveCoaches.js';
// import SubscriptionPage from './layouts/subscriptionPage.js';
import UserGuides from './features/resources/userGuides.js';
// import ExcalidrawCanvas from './features/excalidraw/excalidraw.js';
// import GymSessions from './features/gym/gymSessions.js';
// import OpponentGameAnalysis from './features/analysis/opponentAnalysis.js';
// import SessionReview from './features/session/sessionReview.js';
// import GamedayProgram from './features/rugbyDay/gamedayProgram.js';
// import AssignRoles from './features/rugbyDay/assignRoles.js';
// import PlayerProgram from './features/rugbyDay/playerProgram.js';
// import TestingSystem from './features/testingSystem/testingSystem.js';
// import AddAnalysis from './features/analysis/addAnalysis.js';
// import SaruTestingSystem from './features/testingSystem/saruTestingSystem.js';
// import CustomTestingSystem from './features/testingSystem/customTestingSystem.js';
// import SaruViewTests from './features/testingSystem/saruViewTests.js';
// import CustomViewTest from './features/testingSystem/customViewTests.js';
// import SaruOverviewTests from './features/testingSystem/saruViewTests.js';
// import CustomOverviewTests from './features/testingSystem/customViewTests.js';
// import InSaruTest from './features/testingSystem/InSaruTest.js';
// import InCustomTest from './features/testingSystem/inCustomTest.js';
// import EditTestingSystem from './features/testingSystem/editTestingSystem.js';
// import ViewSaruResults from './features/testingSystem/viewSaruResults.js';
// import ViewTestResults from './features/testingSystem/viewTestResults.js';
// import AddTodayGymSession from './features/gym/addTodayGymSession.js';
// import AddFutureGymSession from './features/gym/addFutureGymSession.js';
// import EditTodayGymSesions from './features/gym/editTodayGymSession.js';
// import EditFutureGymSessions from './features/gym/editFutureGymSession.js';
// import ViewSessionReview from './features/session/viewSessionReview.js';
import LandingPage from './layouts/landing/landingPage.js';
// import ViewAnalysis from './features/analysis/viewAnalysis.js';
import Loader from './layouts/loader.js';
import { Suspense } from 'react';
import { lazy } from 'react';
import ContactUs from './layouts/landing/contactUs.js';
export const appBarHeight = "4em"

// Lazy load all components
//const Stats = lazy(() => import('./features/stats/stats.js'));
const Aside = lazy(() => import('./components/Aside.js'));
//const Session = lazy(() => import('./features/session/session.js'));
//const Calendar = lazy(() => import('./features/calendar/calendar.js'));
//const Settings = lazy(() => import('./features/settings/settings.js'));
//const Paperbase = lazy(() => import('./features/dashboard/Paperbase.js'));
//const SignInSide = lazy(() => import('./features/signIn/signIn.js'));
//const SignUp = lazy(() => import('./features/signUp/signUp.js'));
//const Dashboard = lazy(() => import('./features/dashboard/dashboard.js'));
const NotFoundPage = lazy(() => import('./layouts/notFoundPage'));
//const GameDay = lazy(() => import('./features/gameday/gameday.js'));
const AddTodaySession = lazy(() => import('./features/session/addTodaySession.js'));
const AddFutureSession = lazy(() => import('./features/session/addFutureSession.js'));
const EditTodaySesions = lazy(() => import('./features/session/editTodaySession.js'));
const EditFutureSessions = lazy(() => import('./features/session/editFutureSessions.js'));
//const Season = lazy(() => import('./features/season/season.js'));
const TrialManager = lazy(() => import('./layouts/trialManager/trialManager.js'));
const TrialPaperbase = lazy(() => import('./layouts/trialManager/trialPaperBase.js'));

const TmSignIn = lazy(() => import('./layouts/trialManager/tmSignInPage.js'));
const TmSetupProfile = lazy(() => import('./layouts/trialManager/tmSetupProfile.js'));
const TmUploadTeams = lazy(() => import('./layouts/trialManager/tmUploadTeams.js'));
const TmGameTime = lazy(() => import('./layouts/trialManager/GameTime/tmGameTime.js'));
const TmReport = lazy(() => import('./layouts/trialManager/tmReport.js'));
const TmUploadTeamsAuto = lazy(() => import('./layouts/trialManager/tmUploadTeamsAuto.js'));
const TmUploadTeamsManual = lazy(() => import('./layouts/trialManager/tmUploadTeamsManual.js'));
const TmGameTimeAuto = lazy(() => import('./layouts/trialManager/GameTime/tmGameTimeAuto.js'));
const TmGameTimeManual = lazy(() => import('./layouts/trialManager/GameTime/tmGameTimeManual.js'));
const RegisterSchool = lazy(() => import('./features/registerSchool/registerSchool.js'));
//const AdminDashboard = lazy(() => import('./layouts/adminDetails/adminDashboard.js'));
const Managers = lazy(() => import('./layouts/adminDetails/managers.js'));
const Schools = lazy(() => import('./layouts/adminDetails/schools.js'));
const Coaches = lazy(() => import('./layouts/adminDetails/coaches.js'));
const Players = lazy(() => import('./layouts/adminDetails/players.js'));
const ManageSCTeam = lazy(() => import('./layouts/adminDetails/manageSCTeam.js'));
const DeveloperSettings = lazy(() => import('./layouts/adminDetails/developerSettings.js'));
const EditCoach = lazy(() => import('./features/settings/editCoach.js'));
const EditManager = lazy(() => import('./layouts/adminDetails/editMangers.js'));
//const ManageCoaches = lazy(() => import('./features/settings/manageCoaches.js'));
const EditPlayer = lazy(() => import('./layouts/adminDetails/editPlayer.js'));
//const Team = lazy(() => import('./features/team/team.js'));
const UpdateSeason = lazy(() => import('./features/season/updateSeason.js'));
//const Resource = lazy(() => import('./features/resources/resources.js'));
const FileView = lazy(() => import('./features/resources/files.js'));
const TemplateView = lazy(() => import('./features/resources/templates.js'));
const ApproveCoaches = lazy(() => import('./layouts/adminDetails/approveCoaches.js'));
const SubscriptionPage = lazy(() => import('./layouts/subscriptionPage.js'));
//const UserGuides = lazy(() => import('./features/resources/userGuides.js'));
const ExcalidrawCanvas = lazy(() => import('./features/excalidraw/excalidraw.js'));
const GymSessions = lazy(() => import('./features/gym/gymSessions.js'));
const OpponentGameAnalysis = lazy(() => import('./features/analysis/opponentAnalysis.js'));
const SessionReview = lazy(() => import('./features/session/sessionReview.js'));
const GamedayProgram = lazy(() => import('./features/rugbyDay/gamedayProgram.js'));
const AssignRoles = lazy(() => import('./features/rugbyDay/assignRoles.js'));
const PlayerProgram = lazy(() => import('./features/rugbyDay/playerProgram.js'));
const TestingSystem = lazy(() => import('./features/testingSystem/testingSystem.js'));
const AddAnalysis = lazy(() => import('./features/analysis/addAnalysis.js'));
const SaruTestingSystem = lazy(() => import('./features/testingSystem/saruTestingSystem.js'));
const CustomTestingSystem = lazy(() => import('./features/testingSystem/customTestingSystem.js'));
const SaruViewTests = lazy(() => import('./features/testingSystem/saruViewTests.js'));
const CustomViewTest = lazy(() => import('./features/testingSystem/customViewTests.js'));
const SaruOverviewTests = lazy(() => import('./features/testingSystem/saruViewTests.js'));
const CustomOverviewTests = lazy(() => import('./features/testingSystem/customViewTests.js'));
const InSaruTest = lazy(() => import('./features/testingSystem/InSaruTest.js'));
const InCustomTest = lazy(() => import('./features/testingSystem/inCustomTest.js'));
const EditTestingSystem = lazy(() => import('./features/testingSystem/editTestingSystem.js'));
const ViewSaruResults = lazy(() => import('./features/testingSystem/viewSaruResults.js'));
const ViewTestResults = lazy(() => import('./features/testingSystem/viewTestResults.js'));
const AddTodayGymSession = lazy(() => import('./features/gym/addTodayGymSession.js'));
const AddFutureGymSession = lazy(() => import('./features/gym/addFutureGymSession.js'));
const EditTodayGymSesions = lazy(() => import('./features/gym/editTodayGymSession.js'));
const EditFutureGymSessions = lazy(() => import('./features/gym/editFutureGymSession.js'));
const ViewSessionReview = lazy(() => import('./features/session/viewSessionReview.js'));
// const LandingPage = lazy(() => import('./layouts/landing/landingPage.js'));
const ViewAnalysis = lazy(() => import('./features/analysis/viewAnalysis.js'));

function Layout({ setLocale }) {
    const dispatch = useDispatch();
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    // const extraFeaturesPaid = useSelector((state) => state.user.profile.extraFeatures);
    const extraFeatures = true
    // const userLoggedIn = useSelector((state) => state.gameday.userLoggedIn)
    const token = localStorage.getItem('token')

    let decodedToken
    if (token !== '' && token !== null) {
        decodedToken = jwtDecode(token)
    }
    else {
        decodedToken = ''
    }

    const tmUserLoggedIn = useSelector((state) => state.tmUser.tmUserLoggedIn);

    const subscriptionStatus = useSelector((state) => state.user.profile?.subscriptionStatus);

    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(false);
    const [toggled, setToggled] = useState(false);
    const theme = useTheme()
    // const devProfile = useSelector((state) => state.user.profile.role)
    // const isManager = useSelector((state) => state.user.profile.role)
    const userProfile = useSelector((state) => state.user.profile)
    const profile = useSelector((state) => state.user.profile)
    let role
    profile !== undefined ? role = profile.role : role = ''

    let subscriptionStatusResult

    if (subscriptionStatus !== undefined) {
        subscriptionStatusResult = subscriptionStatus
    } else {
        subscriptionStatusResult = undefined
    }

    // const role = useSelector((state) => state.user.profile.role ? state.user.profile.role : '')



    const isTokenExpired = () => {
        if (!decodedToken || !decodedToken.exp) {
            // If there is no expiration time in the token, consider it expired
            return true;
        }

        // Convert the expiration time to a Date object
        const expirationDate = new Date(decodedToken.exp * 1000); // Multiply by 1000 to convert seconds to milliseconds

        // Compare the expiration date with the current date
        return expirationDate <= new Date();
    };
    let admin
    let manager

    useEffect(() => {
        if (decodedToken) {
            dispatch(setProfile(decodedToken));

            if (isTokenExpired()) {
                setUserLoggedIn(false);
            } else {
                setUserLoggedIn(true);
            }
        } else {
            setUserLoggedIn(false);
        }
        // console.log('SubscriptionStatus in useEffect: ', subscriptionStatus)
    }, [token, subscriptionStatus]);

    // Usage


    if (role === 'Admin') {
        dispatch(setAdmin(true))
        admin = true
        // console.log('Admin is true') 
    }
    else if (role === 'Manager') {
        dispatch(setAdmin(false))
        manager = true;
        // console.log('Manager is true')
    }
    else {
        dispatch(setAdmin(false))
        // console.log('Manager is true')
    }



    function AuthenticatedRoutes() {
        // ... (copy all the routes that should be displayed when the user is logged in)
        // Example:
        return (
            <Suspense
                fallback={
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Loader />
                    </div>
                }>

                <Routes >
                    {/* Dev routes */}
                    <>
                        {
                            admin ?
                                <>
                                    <Route path="/AdminDashboard" element={<AdminDashboard />} />
                                    <Route path="/Managers" element={<Managers />} />
                                    <Route path="/Managers/EditManager/:id" element={<EditManager />} />
                                    <Route path="/Managers/EditCoach/:schoolId/:coachId" element={<EditCoach />} />
                                    <Route path="/Managers/EditPlayer/:schoolId/:playerId" element={<EditPlayer />} />

                                    <Route path="/Schools" element={<Schools />} />
                                    <Route path="/Coaches" element={<Coaches />} />
                                    <Route path="/ApproveCoaches" element={<ApproveCoaches />} />
                                    <Route path="/Players" element={<Players />} />
                                    <Route path="/ManageSCTeam" element={<ManageSCTeam />} />
                                    <Route path="/DeveloperSettings" element={<DeveloperSettings />} />
                                    <Route path="/Resource" element={<Resource />} />
                                    <Route path="/Resource/Files" element={<FileView />} />
                                    <Route path="/Resource/Templates" element={<TemplateView />} />
                                    <Route path="/Resource/UserGuides" element={<UserGuides />} />

                                </>
                                :
                                <>
                                    {/* <Route path="/AdminDashboard" element={<NotFoundPage />} />
                                    <Route path="/Managers" element={<NotFoundPage />} />
                                    <Route path="/Managers/EditManager/:id" element={<NotFoundPage />} />
                                    <Route path="/Managers/EditCoach/:schoolId/:coachId" element={<NotFoundPage />} />
                                    <Route path="/Managers/EditPlayer/:schoolId/:playerId" element={<NotFoundPage />} />

                                    <Route path="/Schools" element={<NotFoundPage />} />
                                    <Route path="/Coaches" element={<NotFoundPage />} />
                                    <Route path="/ApproveCoaches" element={<NotFoundPage />} />
                                    <Route path="/Players" element={<NotFoundPage />} />
                                    <Route path="/ManageSCTeam" element={<NotFoundPage />} />
                                    <Route path="/DeveloperSettings" element={<NotFoundPage />} />
                                    <Route path="/Resource" element={<NotFoundPage />} />
                                    <Route path="/Resource/Files" element={<NotFoundPage />} />
                                    <Route path="/Resource/Templates" element={<NotFoundPage />} /> */}

                                </>
                        }
                    </>

                    <Route path="/" element={<Outlet />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    {/* Teams */}
                    {/* <Route path="/Teams" element={<Team />} /> */}
                    <Route path="/Teams" element={<Team />} />
                    <Route path="/EditPlayer/:schoolId/:playerId" element={<EditPlayer />} />
                    {/* Stats */}
                    <Route path="/Stats" element={<Stats />} />
                    {/* <Route path="/EasterEgg" element={<NotFoundPage />} /> */}


                    {/* Sessions */}
                    <Route path="/Sessions" element={<Session />} />
                    <Route path="/Sessions/addSession" element={<AddTodaySession />} />
                    <Route path="/Sessions/addFutureSession" element={<AddFutureSession />} />
                    <Route path="/Sessions/editTodaySessions" element={<EditTodaySesions />} />
                    <Route path="/Sessions/editFutureSessions" element={<EditFutureSessions />} />

                    {/* Calendar */}
                    <Route path="/Calendar" element={<Calendar />} />

                    {/* Settings */}

                    <>
                        {
                            manager ?
                                <>
                                    <Route path="/Settings" element={<ManageCoaches />} />
                                    <Route path="/Settings/EditProfile/:schoolId/:coachId" element={<EditCoach />} />
                                </>
                                :
                                <>
                                    <Route path="/Settings/:schoolId/:coachId" element={<Settings />} />
                                </>
                        }
                    </>
                    <Route path="/Settings" element={<Settings />} />

                    {/* Game Day */}
                    <Route path="/GameDay" element={<GameDay />} />

                    {/* Season */}
                    <Route path="/season" element={<Season />} />
                    <Route path="/Season/UpdateSeason" element={<UpdateSeason />} />


                    {/* Resources */}
                    <Route path="/Resource" element={<Resource />} />
                    <Route path="/Resource/Files" element={<FileView />} />
                    <Route path="/Resource/Templates" element={<TemplateView />} />
                    <Route path="/Resource/UserGuides" element={<UserGuides />} />


                    {/* Extra Features */}
                    {
                        extraFeatures === true ?
                            <>
                                <Route path="/DrawAndShare" element={<ExcalidrawCanvas />} />

                                {/* Gym */}
                                <Route path="/Gym" element={<GymSessions />} />
                                <Route path="/Gym/addGymSession" element={<AddTodayGymSession />} />
                                <Route path="/Gym/addFutureGymSession" element={<AddFutureGymSession />} />
                                <Route path="/Gym/editTodayGymSessions" element={<EditTodayGymSesions />} />
                                <Route path="/Gym/editFutureGymSessions" element={<EditFutureGymSessions />} />


                                <Route path="/OpponentGameAnalysis" element={<OpponentGameAnalysis />} />
                                <Route path="/OpponentGameAnalysis/addAnalysis" element={<AddAnalysis />} />
                                <Route path="/OpponentGameAnalysis/ViewAnalysis/:id" element={<ViewAnalysis />} />

                                <Route path="/Review" element={<SessionReview />} />
                                <Route path="/Review/ViewReview/:id" element={<ViewSessionReview />} />
                                <Route path="/GamedayRoles" element={<AssignRoles />} />
                                <Route path="/RugbyProgram" element={<GamedayProgram />} />
                                <Route path="/PlayerProgram" element={<PlayerProgram />} />

                                {/* Testing System */}
                                <Route path="/TestingSystem" element={<TestingSystem />} />
                                <Route path="/TestingSystem/EditTestingSystem/:id" element={<EditTestingSystem />} />
                                {/* Saru */}
                                <Route path="/SARUTestingSystem" element={<SaruOverviewTests />} />
                                <Route path="/InspectSARUTestingSystem" element={<SaruTestingSystem />} />
                                <Route path="/InSaruTests" element={<InSaruTest />} />
                                <Route path="/ViewSaruResults/:runTimeReceived" element={<ViewSaruResults />} />

                                {/* Custom */}
                                <Route path="/CustomTestingSystem" element={<CustomOverviewTests />} />
                                <Route path="/InspectCustomTestingSystem" element={<CustomTestingSystem />} />
                                <Route path="/InCustomTest/:id" element={<InCustomTest />} />
                                <Route path="/ViewTestResults/:id/:runtimeReceived" element={<ViewTestResults />} />


                            </>
                            :
                            <>
                                <Route path="/DrawAndShare" element={<NotFoundPage />} />
                                <Route path="/TestingSystem" element={<NotFoundPage />} />
                                <Route path="/Gym" element={<NotFoundPage />} />
                                <Route path="/OpponentGameAnalysis" element={<NotFoundPage />} />
                                <Route path="/Review" element={<NotFoundPage />} />
                                <Route path="/GamedayRoles" element={<NotFoundPage />} />
                                <Route path="/RugbyProgram" element={<NotFoundPage />} />
                                <Route path="/PlayerProgram" element={<NotFoundPage />} />
                            </>
                    }

                </Routes>

            </Suspense>
        );
    }



    return (
        <>
            <Router>
                <div>
                    {userLoggedIn ? (
                        <>
                            {/* {console.log("subscriptionStatus:", subscriptionStatus)} */}
                            {subscriptionStatusResult !== 'Disbabled' ? (
                                <Paperbase>
                                    <AuthenticatedRoutes />
                                </Paperbase>
                            ) : (
                                <SubscriptionPage />
                            )}
                        </>
                    ) : (
                        <>
                            <Routes >
                                <Route path="*" element={<LandingPage />} />
                                <Route path="/" element={<LandingPage />} />
                                {/* Original Default layout:
                                <Route path="*" element={<SignInSide />} />
                                <Route path="/" element={<SignInSide />} /> */}
                                {/* <Route path="/tmSignIn" element={<TmSignIn />} /> */}
                                <Route path="/signIn" element={<SignInSide />} />
                                <Route path="/signup" element={<SignUp />} />
                                <Route path="/contactUs" element={<ContactUs />} />
                                <Route path="/registerSchool" element={<RegisterSchool />} />
                                <Route path="/paper" element={<NotFoundPage />} />
                                <Route path="/dashboard" element={<NotFoundPage />} />
                                <Route path="/GameDay" element={<NotFoundPage />} />
                                <Route path="/Teams" element={<NotFoundPage />} />
                                <Route path="/Stats" element={<NotFoundPage />} />
                                <Route path="/Sessions" element={<NotFoundPage />} />
                                <Route path="/Sessions/addSession" element={<NotFoundPage />} />
                                <Route path="/Sessions/addFutureSession" element={<NotFoundPage />} />
                                <Route path="/Sessions/editTodaySessions" element={<NotFoundPage />} />
                                <Route path="/Sessions/editFutureSessions" element={<NotFoundPage />} />
                                <Route path="/Calendar" element={<NotFoundPage />} />
                                <Route path="/Settings" element={<NotFoundPage />} />
                                <Route path="/season" element={<NotFoundPage />} />
                                <Route path="/Resource" element={<NotFoundPage />} />
                                <Route path="/Resource/Files" element={<NotFoundPage />} />
                                <Route path="/Resource/Templates" element={<NotFoundPage />} />

                            </Routes>
                        </>
                    )}
                </div>
            </Router>
        </>
    );
}

export default Layout;