import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Select, MenuItem, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Loader from '../../layouts/loader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { ReservePropagation } from './teamLists';

const defaultTheme = createTheme();

function ReservePopup(props) {
    const { name, isOpen, setReserveReportWindowStatus, teams } = props;
    const [playerName, setPlayerName] = useState(name !== undefined ? name : '');
    const [team, setTeam] = useState('');
    const [backendTeams, setBackendTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [reservePosition, setReservePosition] = useState();
    const [positions, setPositions] = useState([
        { id: 1, name: 'Loose Head Prop (1)' },
        { id: 2, name: 'Hooker (2)' },
        { id: 3, name: 'Tight Head Prop (3)' },
        { id: 4, name: 'Lock (4)' },
        { id: 5, name: 'Lock (5)' },
        { id: 6, name: 'Openside Flanker (6)' },
        { id: 7, name: 'Blindside Flanker (7)' },
        { id: 8, name: 'Eight Man (8)' },
        { id: 9, name: 'Scrumhalf (9)' },
        { id: 10, name: 'Flyhalf (10)' },
        { id: 11, name: 'Left Wing (11)' },
        { id: 12, name: 'Inside Center (12)' },
        { id: 13, name: 'Outside Center (13)' },
        { id: 14, name: 'Right Wing (14)' },
        { id: 15, name: 'Fullback (15)' },
    ]);


    useEffect(() => {
        // Update playerName when name prop changes
        setPlayerName(name !== undefined ? name : '');
        // fetchTeams();
    }, [name]);

    const { onReserveSubmit } = React.useContext(ReservePropagation);

    const handleSubmit = () => {
        setReserveReportWindowStatus(false);
        console.log('Team selected: ', team);


        if (!reservePosition || !team) {
            setError(true)
            setMessage('Complete all fields');
            setOpenSnackbar(true);
        }
        else {
            // setError(false)
            // setMessage('Reserve added');
            // setOpenSnackbar(true);
            console.log('ReservePosition: ', reservePosition)
            onReserveSubmit(props.playerId, team, reservePosition);
        }
    };

    const handleRemoveReserve = () => {
        setReserveReportWindowStatus(false);
        onReserveSubmit(props.playerId, -1);

    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleClose = () => {
        setReserveReportWindowStatus(false);
        setPlayerName('');
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={defaultTheme}>
                <Dialog open={isOpen} maxWidth="xs" fullWidth={true}>
                    <DialogTitle>
                        <Typography variant="h5" component="div">Select Reserve Team</Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            disabled={true}
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={playerName}
                        />
                        <p>Team you are benching for</p>
                        {
                            isLoading ?
                                <Loader />
                                :
                                <Select
                                    id="demo-simple-select"
                                    value={team || ''}
                                    label="Age Group"
                                    onChange={(event) => {
                                        setTeam(event.target.value)
                                    }}
                                    sx={{ width: '300px' }}
                                >
                                    {
                                        teams.map((team) => {
                                            return (
                                                <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                        }

                        <br />
                        <p>Position</p>
                        {
                            isLoading ?
                                <Loader />
                                :
                                <Select
                                    id="demo-simple-select"
                                    value={reservePosition || ''}
                                    label="Age Group"
                                    onChange={(event) => {
                                        setReservePosition(event.target.value)
                                    }}
                                    sx={{ width: '300px' }}
                                >
                                    {
                                        positions.map((position) => {
                                            return (
                                                <MenuItem key={position.id} value={position.id}>
                                                    {position.name}
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                        }

                        <br />

                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant='contained' style={{ backgroundColor: 'red', color: 'white' }} onClick={() => { handleRemoveReserve() }}>
                            Remove Reserve
                        </Button>
                        <div>
                            <Button onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={() => { handleSubmit() }}>
                                Submit
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

            </ThemeProvider>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment >
    );
}

export default React.memo(ReservePopup);
