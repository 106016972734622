
//Middel one
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import LogoSelector from '../../services/logoSelector';
import SquadControlLogo from '../../assets/SquadControlWhite.png'
import SquadControlLogoRB from '../../assets/SquadControlWhiteRB.png'

function PrintSeasons(props) {
    const schoolName = useSelector((state) => state.user.schoolName);
    const ageGroup = useSelector((state) => state.user.profile.ageGroup);
    const [opponentLogos, setOpponentLogos] = useState({});
    const [mySchoolLogo, setMySchoolLogo] = useState(null);
    const [squadControlLogo, setSquadControlLogo] = useState(null);


    useEffect(() => {
      
        const fetchLogos = async () => {
            // Fetch logos for all opponents in props.season.games
            const logos = {};
            if (props.season) {
                for (const game of props?.season?.games) {
                    if (!logos[game.opponent]) {
                        logos[game.opponent] = await LogoSelector(game.opponent);
                    }
                }
            }

            setOpponentLogos(logos);

            // Fetch logo for my school
            setMySchoolLogo(await LogoSelector(schoolName));
            // Fetch logo for Squad Control
            setSquadControlLogo(SquadControlLogoRB);
        };

        fetchLogos();
    }, [props]);

    async function PrintSeason() {
        const doc = new jsPDF();

        const gamesPerPage = 3;
        const totalGames = props.season.games.length;

        for (let start = 0; start < totalGames; start += gamesPerPage) {
            const end = Math.min(start + gamesPerPage, totalGames);

            // Add header on each page
            addHeader(doc, schoolName, ageGroup, props.activeTeam);

            renderPage(doc, props.season?.games?.slice(start, end), opponentLogos);
            if (end < totalGames) {
                doc.addPage();
                // Add header on each page
                addHeader(doc, schoolName, ageGroup, props.activeTeam);
            }
        }

        const date = new Date();
        doc.save(`${props.seasonType} for ${props.activeTeam} ${date.toDateString()}.pdf`);
    }

    function addHeader(doc, schoolName, ageGroup, activeTeam) {
        doc.setFontSize(16);
        doc.text(`${schoolName}`, 76, 10);
        doc.setFontSize(12);
        doc.text(`u/${ageGroup}`, 100, 20);
        doc.text(`${activeTeam}`, 97, 30);

        doc.setFontSize(15);
        doc.text(`${props.seasonType} for ${props.activeTeam}`, 80, 41);

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Copyright © Squad Control`, 83, doc.internal.pageSize.height - 10);
    }

    function renderPage(doc, games, opponentLogos) {
        let currentX = 15;
        let spaceBetweenColumns = 95;
        let spaceBetweenRows = 75;
        let currentY = 50;

        games.forEach((game, index) => {
            renderStatGrid(doc, game, currentX, currentY, opponentLogos);
            currentY += spaceBetweenRows;
        });
    }

    async function renderStatGrid(doc, game, x, y, opponentLogos) {
        const { opponent, date, venue, type, played } = game;
       

        const locationOfT = date.indexOf('T');
        const newDate = date.slice(0, locationOfT);

        const logoWidth = 50; // Set the desired width for logos
        const logoHeight = 50; // Set the desired height for logos

        doc.setFillColor(240, 240, 240);
        doc.rect(x, y, 180, 60, 'F');

        doc.setDrawColor(180, 180, 180);
        doc.setLineWidth(1);
        doc.line(x, y + 60, x + 180, y + 60);

        doc.setFontSize(18);
        doc.setTextColor(100, 149, 237);

        doc.addImage(mySchoolLogo, 'PNG', 15, y + 5, logoWidth, logoHeight);

        // doc.text(opponent, opponent.length > 18 ? x + 58 : opponent.length > 22 ? x + 60 : x + 66, y + 8);
        doc.text(
            opponent,
            opponent.length > 23 ? x + 52 : opponent.length > 18 ? x + 60 : opponent.length < 18 ? x + 66 : x + 58,
            y + 8
        );

        doc.setFontSize(16);
        doc.setTextColor(0);
        doc.text(newDate, x + 76, y + 20);

        doc.setFontSize(12);
        doc.text(`${venue} game`, x + 78, y + 30);
        doc.text(`Type of game: ${type}`, type === 'Derby' ? x + 68 : x + 68, y + 40);
        doc.text(played ? 'Played' : '', x + 85, y + 50);

        const opponentLogo = opponentLogos[opponent];


        const opponentLogoWidth = 0.8 * logoWidth; // 80% of the regular size
        const opponentLogoHeight = 0.8 * logoHeight; // 80% of the regular size

    
        if (opponentLogo) {
            doc.addImage(opponentLogo, 'PNG', x + 130, y + 5, opponentLogoWidth, opponentLogoHeight);
        } else {
            // Provide a default logo or handle the case when opponentLogo is not available
            doc.addImage(squadControlLogo, 'PNG', x + 130, y + 5, logoWidth, logoHeight);
        }
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="contained" onClick={PrintSeason} style={{ margin: '5px' }}>
                Print Season
            </Button>
        </div>
    );
}

export default PrintSeasons;

